//import { getFileList } from "service/FileManagerService";
import { FilePathBreadcCrumbs } from "./breadcrumbs";
import IFileInfo from "./IFileInfo";
import InnoFileManager from "./table";
import LeftTreeView from "./treeview";
import React from "react";
import { IFileManagerData, IUserDirs } from ".";
import { Hidden, styled } from "@mui/material";
import { Box } from "@mui/system";

interface FileExplorerProps {
  height: number;
  onSelectionChange?: (rows: IFileInfo[]) => void | undefined;
  onSelectAndDialogClose?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  fileManagerData: IFileManagerData;
  defaultPath?: string;
  onChangedPath: (path: string) => void;
  disabledLeft: boolean;
}

export default function FileExplorer({
  height,
  onSelectionChange,
  onSelectAndDialogClose,
  onChangedPath,
  disabledLeft,
  fileManagerData = {
    currentBasePath: "/home/innopam",
    currentFilelist: [],
    homeDir: "/",
    userDirs: { documents: "", music: "", pictures: "", videos: "" },
  },
  defaultPath = "/home/innopam",
}: FileExplorerProps): React.ReactElement {
  return (
    <>
      <Root style={{ height: height }}>
        {!disabledLeft && (
          <Hidden mdDown>
            <div className="left">
              <LeftTreeView
                setTrySearchBasePath={onChangedPath}
                homePath={fileManagerData.homeDir}
                userDirs={fileManagerData.userDirs}
              />
            </div>
          </Hidden>
        )}
        <Box
          className="right"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FilePathBreadcCrumbs
            homePath={fileManagerData.homeDir}
            currentPath={fileManagerData.currentBasePath}
            setTrySearchBasePath={onChangedPath}
          />
          <InnoFileManager
            rows={fileManagerData.currentFilelist.map((data, id) => ({
              ...data,
              id,
            }))}
            onResourceItemDoubleClick={(event, idx, row) => {
              if (row.isDirectory) {
                onChangedPath && onChangedPath(row.path);
              } else {
                onSelectionChange && onSelectionChange([row]);
                onSelectAndDialogClose && onSelectAndDialogClose();
                //TODO 더블클릭한 아이템이 파일일경우 처리
              }
            }}
            onSelectionChange={onSelectionChange}
            // onResourceItemRightClick={({ event, number, rowData }) => console.log("onResourceItemRightClick", event, number, rowData)}
          />
        </Box>
      </Root>
    </>
  );
}
const Root = styled("div")(({ theme }) => ({
  display: "flex",
  minWidth: 920,
  flexDirection: "row",
  borderBlock: "1px solid #eee",

  ".left": {
    width: 240,
    height: "100%",
    paddingRight: 24,
    border: "none",
    backgroundImage: "linear-gradient(to right, #fff 0%, #fff 93%, #eee 100%)",
  },
  ".right": { flexGrow: 1, height: "100%" },
  selectedDataArea: {
    flexGrow: 1,
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#E3F2FD",
    "& > *": {
      margin: theme.spacing(1, 2),
    },
  },
}));

const getUserDirs = (
  homeDir: string,
  currentFilelist: IFileInfo[]
): IUserDirs => {
  const findedPath = (name1: string, name2: string): string =>
    currentFilelist.find(
      (d) => d.name.toLowerCase() === name1 || d.name.toLowerCase() === name2
    )?.path || "";

  const documents = findedPath("문서", "documents"),
    pictures = findedPath("사진", "pictures"),
    music = findedPath("음악", "music"),
    videos = findedPath("비디오", "videos");

  return { documents, pictures, music, videos };
};
