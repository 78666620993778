import { Box, Skeleton, styled } from "@mui/material";
import React, { Suspense } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import CustomButton from "src/components/common/CustomButton";
import CustomTable from "src/components/common/CustomTable";
import Title from "src/components/common/Title";
import Description from "src/components/Description";
import { IExperiment, IMetrics } from "src/dto";
import { alertOpenState } from "src/recoil/alert";
import { refreshState, systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import { useExperimentList } from "src/service/experiment/experimentService";
import useLearning from "src/service/hook/useLearning";
import {
  handlerContinueRun,
  handlerDeleteRun,
  handlerPauseRun,
  useRunList,
} from "src/service/run/runService";
import useColumns from "src/service/run/useColumns";
import { IState } from "./Run";

type Props = {};

interface IRow extends IMetrics {
  [index: string]: string | number | undefined;
}

export interface IName {
  name: string;
}

interface ILocationProps {
  state: IName;
}

function Experiments({}: Props) {
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const { experimentId } = useParams();
  const { state } = useLocation() as unknown as {
    state: IState;
  };
  const { data } = useRunList({ experimentId, refresh });
  const info = useExperimentList<IExperiment>({
    refresh,
    name: experimentId,
  }).data;
  const [systemTypeState, setSystemTypeState] = useRecoilState(systemType);
  const secondCol = useColumns({ type: systemTypeState });
  const [rows, setRows] = React.useState<any[]>([]); // any Type 변경필요
  const firstCol = [
    ["", 8],
    ["Metrics", secondCol.length - 7],
  ];
  React.useEffect(() => {
    if (info) {
      if (info.type === "classification") {
        setSystemTypeState("ic");
      } else if (info.type === "objectDetection") {
        setSystemTypeState("od");
      } else if (info.type === "segmentation") {
        setSystemTypeState("ss");
      }
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      setRows((_prev) => {
        return data.map((el) => {
          let tempObj = { ...el };
          let temp = el.metrics;
          delete tempObj.metrics;

          return {
            ...tempObj,
            ...temp,
            duration: el.endTime ? el.endTime : "-",
          };
        });
      });
    }
  }, [data]);

  const handlerOpenCreateRun = useLearning({
    typeArr: [
      "trainClassification",
      "trainObjectDetection",
      "trainSegmentation",
    ],
    type: "train",
    systemTypeState,
    state,
  });

  const handlerDeleteTrainingRun = async () => {
    if (experimentId) {
      const res = await handlerDeleteRun(experimentId);
      console.log(res);
    }
  };
  const handlerPauseTrainingRun = async () => {
    if (experimentId) {
      const res = await handlerPauseRun(experimentId);
      console.log(res);
    }
  };
  const handlerContinueTrainingRun = async () => {
    if (experimentId) {
      const res = await handlerContinueRun(experimentId);
      console.log(res);
    }
  };

  return (
    <Root>
      <Suspense>
        <Title text={`${state.experimentName}`} type={"default"} />
        <Box className="experimentBody">
          {info && <Description info={info} />}
          <Box className="buttons">
            <CustomButton
              handleFunction={handlerOpenCreateRun}
              text="Learning Run"
            />
            <CustomButton
              handleFunction={handlerDeleteTrainingRun}
              text="delete run"
              className="delete"
            />
            <CustomButton
              handleFunction={handlerPauseTrainingRun}
              text="Pause Learning Run"
            />
            <CustomButton
              handleFunction={handlerContinueTrainingRun}
              text="Continue Learning Run"
            />
          </Box>
          {data ? (
            <CustomTable<IRow>
              firstCol={firstCol}
              secondCol={secondCol}
              rows={rows}
              isCheck={true}
              isPointer={true}
            />
          ) : (
            <Skeleton height={200} />
          )}
        </Box>
      </Suspense>
    </Root>
  );
}

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  "& .experimentBody": {
    padding: "60px",
  },
}));

export default Experiments;
