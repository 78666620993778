import React from "react";

type Props = {};

function useOnOff() {
  const [isOnOff, setIsOnOff] = React.useState(false);
  const handlerOnOff = React.useCallback((boolean: boolean) => {
    setIsOnOff(!boolean);
  }, []);
  const handler = React.useCallback((boolean: boolean) => {
    setIsOnOff(boolean);
  }, []);
  return {
    isOnOff: isOnOff,
    handlerOnOff: handlerOnOff,
    handler: handler,
  };
}

export default useOnOff;
