import useSWR from "swr";
import { fetcher } from "../Fetcher";

export interface IFileInfo {
  name: string;
  isDirectory: boolean;
  path: string;
  size: number | string;
  createDate: Date | string;
  updateDate: Date | string;
  ext: string;
}

export type KeyOfFileInfo = keyof IFileInfo;

export default function useFileManager({ path }: { path: string }): {
  result: { directoryInfo: IFileInfo[]; basePath: string } | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, error } = useSWR<any>(
    [`/file-manager`, path],
    async (url) => {
      let params = { path };
      return fetcher({ url, params }).catch((err) => {
        return err;
      });
    },
    { suspense: true, focusThrottleInterval: 5000, shouldRetryOnError: false }
  );
  return {
    result: data,
    isLoading: !error && !data,
    //isLoading: true,
    isError: error as boolean,
  };
}
