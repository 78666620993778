import styled from "@emotion/styled";
import { Box, Input, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  title: string;
  handlerFunction: (e: React.ChangeEvent<HTMLInputElement>) => void;
  align?: "right" | "left" | "inherit" | "center" | "justify" | undefined;
  defaultValue?: string | number;
  type?: "input" | "box";
  inputClickFunction?: (title: string) => void;
  path?: string;
  valueType?: string;
}

function CustomInput({
  title,
  handlerFunction,
  align = "right",
  defaultValue,
  type = "input",
  inputClickFunction = (title: string) => {},
  path = "",
  valueType = "text",
}: IProps) {
  const { t, i18n } = useTranslation();
  return (
    <Root>
      <Typography align={align}>
        {title === "lr" ? t("leaningRate") : t(title)}{" "}
      </Typography>
      {type === "input" ? (
        <input
          type={valueType}
          step={
            valueType === "number"
              ? title === "epoch"
                ? "1"
                : "0.01"
              : undefined
          }
          min={valueType === "number" && title !== "epoch" ? "0" : undefined}
          max={valueType === "number" && title !== "epoch" ? "1" : undefined}
          onChange={handlerFunction}
          name={title}
          defaultValue={defaultValue !== undefined ? defaultValue : ""}
        />
      ) : (
        <Box
          className="UrlBox"
          onClick={() => {
            inputClickFunction(title);
          }}
        >
          {path}
        </Box>
      )}
    </Root>
  );
}

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  "& input": {
    marginLeft: "20px",
    flex: 1,
    fontSize: "13px",
  },
  "& .MuiTypography-root": {
    flex: 0.3,
  },
  "& .UrlBox": {
    marginLeft: "20px",
    flex: 1,
    border: "1px solid",
    borderColor: "rgb(118, 118, 118)",
    borderRadius: "2px;",
    cursor: "pointer",
    padding: "1px 2px;",
    fontSize: "13px",
    fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
    fontWeight: "400;",
    lineHeight: "1.5;",
    letterSpacing: "0.00938em;",
  },
}));

export default CustomInput;
