import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Input, Popover, styled, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TrainSegmentationReqDto } from "src/dto";
import { systemType } from "src/recoil/common";
import useModel from "src/service/common/useModel";
import useInputs from "src/service/hook/useInputs";
import { InputWrapper } from "../common/CustomAutoComplete";
import CustomComboBox from "../common/CustomComboBox";
import { ContentBox } from "../common/CustomDialog";
import CustomInput from "../common/CustomInput";
import { FileExplorerDialog } from "../fileExplorer";
import { RgbColorPicker, RgbColor } from "react-colorful";

function SegmentationLeaning({
  handlerSetData,
}: {
  handlerSetData: <T>(data: T) => void;
}) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [urlKey, setUrl] = React.useState("");
  const [classesTags, setClassesTags] = React.useState<string[]>([]);
  const [paletteTags, setPaletteTags] = React.useState<string[]>([]);
  const systemTypeState = useRecoilValue(systemType);
  const { result } = useModel({ systemTypeState });
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [rgb, setRgb] = React.useState<RgbColor>({ r: 255, g: 255, b: 255 });

  const fileDialogOpenHandler = (title: string) => {
    setUrl((prev) => title);
    setIsOpen(true);
  };
  const [
    { lr, srcCfgPath, epoch, dataRoot, classes, palette },
    handlerInput,
    reset,
  ] = useInputs<TrainSegmentationReqDto>({
    lr: 0.0001,
    srcCfgPath: Object.keys(result!)[0],
    epoch: 50,
    dataRoot: "",
    classes: [],
    palette: [],
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlerPushPaletteColor = () => {
    setPaletteTags((prev) => [...prev, `[${rgb.r},${rgb.g},${rgb.b}]`]);
  };

  React.useEffect(() => {
    handlerSetData<TrainSegmentationReqDto>({
      lr: Number(lr),
      srcCfgPath,
      epoch: Number(epoch),
      dataRoot,
      classes: classesTags,
      palette: paletteTags,
    });
  }, [
    lr,
    srcCfgPath,
    epoch,
    dataRoot,
    classes,
    palette,
    classesTags,
    paletteTags,
  ]);
  return (
    <Box>
      <ContentBox>
        <CustomInput
          valueType="number"
          title={"lr"}
          handlerFunction={handlerInput}
          defaultValue={lr}
        />
      </ContentBox>
      <ContentBox>
        <CustomInput
          valueType="number"
          title={"epoch"}
          handlerFunction={handlerInput}
          defaultValue={epoch}
        />
      </ContentBox>
      <ContentBox>
        <CustomInput
          title={"dataRoot"}
          handlerFunction={handlerInput}
          type={"box"}
          inputClickFunction={fileDialogOpenHandler}
          path={dataRoot}
        />
      </ContentBox>
      <ContentBox sx={{ display: "flex" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("srcCfgPath")} :
        </Typography>
        {result && (
          <CustomComboBox
            combos={result}
            name="srcCfgPath"
            handlerFunction={handlerInput}
          />
        )}
      </ContentBox>
      <ContentBox sx={{ display: "flex" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("classes")} :
        </Typography>
        <InputWrapper
          sx={{
            borderColor: "rgb(118, 118, 118)",
            flex: 1,
            marginLeft: "20px",
          }}
        >
          {classesTags.map((el: string, idx: number) => (
            <div className="StyledCustomTag" key={idx}>
              <span>{el}</span>
              <CloseIcon
                onClick={() => {
                  setClassesTags((prev) => prev.filter((name) => name !== el));
                }}
              />
            </div>
          ))}
          <input
            name={"classes"}
            placeholder="please press enter key to put in classes field"
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                const tags = e.currentTarget.value.split(",")[0];
                setClassesTags((prev) => {
                  return [...prev, tags];
                });
                e.currentTarget.value = "";
              }
            }}
          />
        </InputWrapper>
      </ContentBox>

      <ContentBox sx={{ display: "flex" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("palette")} :
        </Typography>
        <InputWrapper
          sx={{
            borderColor: "rgb(118, 118, 118)",
            flex: 1,
            marginLeft: "20px",
          }}
        >
          {paletteTags.map((el: string, idx: number) => (
            <div className="StyledCustomTag" key={idx}>
              <span>{el}</span>
              <CloseIcon
                onClick={() => {
                  setPaletteTags((prev) => prev.filter((name) => name !== el));
                }}
              />
            </div>
          ))}
          <input
            name={"palette"}
            onKeyUp={(e) => {
              const firstCheck = e.currentTarget.value[0];
              const lastCheck =
                e.currentTarget.value[e.currentTarget.value.length - 2];
              const commaCheck = e.currentTarget.value.split(",");
              if (
                e.code === "Comma" &&
                firstCheck === "[" &&
                lastCheck === "]" &&
                commaCheck.length === 4
              ) {
                const tags = e.currentTarget.value.slice(0, -1);
                setPaletteTags((prev) => {
                  return [...prev, tags];
                });
                e.currentTarget.value = "";
              }
            }}
          />
          <span
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              background:
                "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)",
            }}
            onClick={(e) => {
              setAnchorEl(
                document.querySelector(".MuiDialog-paperScrollPaper")
              );
            }}
          ></span>
        </InputWrapper>
      </ContentBox>

      {isOpen && (
        <Suspense>
          <FileExplorerDialog
            title="서버 스토리지 내부 경로 선택"
            description="FTP를 통해 미리 업로드된 파일이 있는 경로(폴더)를 선택합니다."
            open={isOpen}
            onClose={(selectedPath: string | undefined, e) => {
              if (selectedPath) {
                handlerInput(e, urlKey, undefined, selectedPath);
              }
              setIsOpen(false);
            }}
          />
        </Suspense>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ boxShadow: "none" }}
      >
        <PopoverBox>
          <RgbColorPicker
            color={rgb}
            onChange={(e) => {
              setRgb((prev) => e);
            }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`R  :`}</Typography>
              <Input
                value={rgb.r}
                sx={{ width: "30px" }}
                onChange={(e) => {
                  setRgb((prev) => ({ ...prev, r: Number(e.target.value) }));
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`G  :`}</Typography>
              <Input
                value={rgb.g}
                sx={{ width: "30px" }}
                onChange={(e) => {
                  setRgb((prev) => ({ ...prev, g: Number(e.target.value) }));
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`B  :`}</Typography>
              <Input
                value={rgb.b}
                sx={{ width: "30px" }}
                onChange={(e) => {
                  setRgb((prev) => ({ ...prev, b: Number(e.target.value) }));
                }}
              />
            </Box>
          </Box>
          <Button
            sx={{ width: "100%", marginTop: "10px" }}
            onClick={handlerPushPaletteColor}
          >
            Add Color
          </Button>
        </PopoverBox>
      </Popover>
    </Box>
  );
}

const PopoverBox = styled(Box)(({ theme }) => ({
  width: "300px",
  height: `${385}px`,
  padding: "20px",
  "& .react-colorful": {
    height: "80%;",
    width: "100%",
  },
}));

export default SegmentationLeaning;
