import axios from "axios";
const url = process.env.REACT_APP_API_ENDPOINT;

export interface IPredictReqDtoTemp {
  inputData: string;
  outputData: string;
  fileName?: string;
  artifactUrl: string;
  multiSpectral?: "RGB" | "multispectral";
}

export interface IPredictReqDto extends IPredictReqDtoTemp {
  outputFileName?: string;
}

export interface PredictClassificationReqDto extends IPredictReqDto {
  [index: string]: string | undefined;
  outputFileName: string | undefined;
}

export interface PredictSegmentationReqDto extends IPredictReqDto {
  [index: string]: string | undefined;
  outputFileName: string;
}
export interface PredictObjectDetectionReqDto extends IPredictReqDto {
  [index: string]: string | undefined;
  outputFileName: string;
}

export const handlerPostStartPredict = async <T,>({
  experiemntId,
  runId,
  type,
  data,
}: {
  experiemntId: string;
  runId: string;
  type: string;
  data: T;
}) => {
  if (url) {
    const res = await axios.post(
      `${url}/experiments/${experiemntId}/predict/${runId}`,
      {
        [type]: { ...data },
      }
    );
    console.log(res);
    return res;
  }
};

export const handlerDeletePredict = async (experiemntId: string) => {
  if (url) {
    const res = await axios.delete(
      `${url}/experiments/${experiemntId}/predict`
    );
    return res;
  }
};

export const handlerPausePredict = async (experiemntId: string) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experiemntId}/predict/pause`
    );
    return res;
  }
};

export const handlerContinuePredict = async (experiemntId: string) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experiemntId}/predict/unpause`
    );
    return res;
  }
};

export const handlerGetHtmlFile = async ({
  experimentId,
  runId,
}: {
  experimentId: string;
  runId: string;
}) => {
  try {
    const res = await axios.get(
      `${url}/experiments/${experimentId}/predict/${runId}/download`
    );
    return res;
  } catch (err: any) {
    return err.response;
  }
};
