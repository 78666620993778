import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  SliderThumb,
  Switch,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

function CustomSlideButton(props: React.HTMLAttributes<any>) {
  const { children, ...other } = props;
  return (
    <CustomSliderThumb {...other}>
      {children}
      <span>{(other as any).props["data-index"]}</span>
    </CustomSliderThumb>
  );
}

interface IProps {
  defaultValue: number;
  handlerInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    sildeName?: string,
    slideVal?: number
  ) => void;
  name: string;
  isMinMax?: boolean;
}

function CustomSlide({
  defaultValue,
  handlerInput,
  name,
  isMinMax = false,
}: IProps) {
  const [value, setValue] = React.useState(defaultValue);

  return (
    <Slider
      components={{ Thumb: CustomSlideButton }}
      componentsProps={{
        thumb: <CustomSlideButton data-index={value} />,
      }}
      onChange={(e, v) => {
        setValue((prev) => v as number);
        handlerInput(
          e as unknown as React.ChangeEvent<HTMLInputElement>,
          name,
          v as number
        );
      }}
      defaultValue={defaultValue}
      min={isMinMax ? 256 : 0}
      max={isMinMax ? 512 : 100}
      step={isMinMax ? 256 : 1}
    />
  );
}

const CustomSliderThumb = styled(SliderThumb)(({ theme }) => ({
  "& span": {
    color: "#fff",
    fontSize: "10px",
  },
}));

export default CustomSlide;
