import { atom } from "recoil";

export const lineOptions = atom<{
  isPoints: boolean;
  lineValue: number;
  xAxis: string;
  yAxis: string[];
}>({
  key: "lineOptions",
  default: {
    isPoints: true,
    lineValue: 1,
    xAxis: "step",
    yAxis: [],
  },
});

export const datasetsState = atom<any>({
  key: "datasetsState",
  default: {
    labels: [""],
    datasets: [
      {
        data: [],
        label: "",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  },
});
