import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  TrainClassificationReqDto,
  TrainObjectDetectionReqDto,
  TrainSegmentationReqDto,
} from "src/dto";
import { IState } from "src/pages/Run";
import { alertOpenState } from "src/recoil/alert";
import { refreshState, systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import handlerValidate from "src/service/hook/handlerValidate";
import useLearning from "src/service/hook/useLearning";
import usePredict from "src/service/hook/usePredict";
import {
  handlerContinueTransferLearning,
  handlerDeleteTransferLearning,
  handlerPauseTransferLearning,
  handlerPostStartTransferLearning,
} from "src/service/run/transferLearningService";
import { useSWRConfig } from "swr";
import CustomButton from "./CustomButton";

interface IProps {
  text: string;
  type: "default" | "button";
  systemTypeState?: "ic" | "od" | "ss";
  artifactUrl?: string;
  status?: string;
}

const Title = ({
  text,
  type,
  systemTypeState,
  artifactUrl,
  status,
}: IProps) => {
  const { t, i18n } = useTranslation();
  const [dialogState, setDialogState] = useRecoilState(dialogOpenState);
  const { state } = useLocation() as unknown as {
    state: IState;
  };
  const setAlertState = useSetRecoilState(alertOpenState);
  const setSystemTypeState = useSetRecoilState(systemType);
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const { mutate } = useSWRConfig();

  const handlerInteractRun = useLearning({
    typeArr: [
      "transferLearningClassification",
      "transferLearningObjectDetection",
      "transferLearningSegmentation",
    ],
    type: "transfer",
    systemTypeState: systemTypeState!,
    state,
    artifactUrl,
  });
  const handlerReasoningRun = usePredict({
    typeArr: [
      "predictClassification",
      "predictObjectDetection",
      "predictSegmentation",
    ],
    systemTypeState: systemTypeState!,
    state,
    artifactUrl,
  });
  React.useEffect(() => {
    if (type === "button") setSystemTypeState(() => state.type);
  }, [state]);

  const handlerDeleteTrainingRun = async () => {
    if (state.experimentId !== undefined) {
      const res = await handlerDeleteTransferLearning(
        state.experimentId,
        state.rowId
      );
      if (res && res.status < 300) {
        mutate(`experiments/${state.experimentId}/run`);
        setRefresh(new Date().getTime());
      }
    }
  };
  const handlerPauseTrainingRun = async () => {
    if (state.experimentId !== undefined) {
      const res = await handlerPauseTransferLearning(
        state.experimentId,
        state.rowId
      );
      if (res && res.status < 300) {
        mutate(`experiments/${state.experimentId}/run`);
        setRefresh(new Date().getTime());
      }
    }
  };
  const handlerContinueTrainingRun = async () => {
    if (state.experimentId !== undefined) {
      const res = await handlerContinueTransferLearning(
        state.experimentId,
        state.rowId
      );
      if (res && res.status < 300) {
        mutate(`experiments/${state.experimentId}/run`);
        setRefresh(new Date().getTime());
      }
    }
  };

  return (
    <Root>
      <Typography variant="h3">{text}</Typography>
      {type === "button" && (
        <Box className="titleButton">
          <Box>
            <CustomButton
              text={t("INFERENCE")}
              handleFunction={handlerReasoningRun}
              className={"interact"}
              disabled={status?.toLowerCase() === "running" ? true : false}
            />
            {status &&
              (status?.toLowerCase() === "running" ||
                status?.toLowerCase() === "pause") && (
                <>
                  <CustomButton
                    text={t("Delete")}
                    handleFunction={handlerDeleteTrainingRun}
                    className={"delete"}
                  />
                  <CustomButton
                    text={t("Pause")}
                    handleFunction={handlerPauseTrainingRun}
                  />
                  <CustomButton
                    text={t("Continue")}
                    handleFunction={handlerContinueTrainingRun}
                  />
                </>
              )}
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "12px",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              text={t("TRANSFER")}
              handleFunction={handlerInteractRun}
              className={"interact"}
              disabled={status?.toLowerCase() === "running" ? true : false}
            />
            {status &&
              (status?.toLowerCase() === "running" ||
                status?.toLowerCase() === "pause") && (
                <>
                  <CustomButton
                    text={t("Delete")}
                    handleFunction={handlerDeleteTrainingRun}
                    className={"delete"}
                  />
                  <CustomButton
                    text={t("Pause")}
                    handleFunction={handlerPauseTrainingRun}
                  />
                  <CustomButton
                    text={t("Continue")}
                    handleFunction={handlerContinueTrainingRun}
                  />
                </>
              )}
          </Box>
        </Box>
      )}
    </Root>
  );
};

const Root = styled(Box)(({ theme }) => ({
  height: "7rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid",
  borderColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.8)"
      : "rgba(0, 0, 0, 0.08)",
  "& .MuiTypography-h3": {
    margin: "0px 0px 0px 60px",
  },
  "& .titleButton": {
    marginRight: "60px",
  },
}));

export default Title;
