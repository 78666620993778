import { atom } from "recoil";

interface IAlert {
  type: string;
  title: string;
  isOpen: boolean;
  handlerFunction: () => void;
  value: string;
}

export const alertOpenState = atom<IAlert>({
  key: "alertOpenState",
  default: {
    type: "",
    title: "",
    isOpen: false,
    handlerFunction: () => {},
    value: "",
  },
});
