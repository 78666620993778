import { Box, Paper, styled, ThemeProvider } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { useRecoilState } from "recoil";
import Title from "src/components/common/Title";
import Information from "src/components/Information";
import Tabs from "src/components/tabs";
import { mainTheme } from "src/config/theme";
import { refreshState } from "src/recoil/common";
import { useRunInfo } from "src/service/run/runService";

export interface IState {
  experimentId: string;
  experimentName: string;
  rowId: string;
  type: "ic" | "od" | "ss";
}

export default function Run() {
  const { state } = useLocation() as unknown as {
    state: IState;
  };
  const [refresh, setRefresh] = useRecoilState(refreshState);

  const { data } = useRunInfo({
    experimentId: state.experimentId,
    runId: state.rowId,
    refresh: refresh,
  });
  return (
    <ThemeProvider theme={mainTheme}>
      <Root>
        <Title
          text={`Run ${data?.runName}`}
          type="button"
          systemTypeState={state.type}
          artifactUrl={data?.artifactUrl}
          status={data?.status}
        />
        <Box sx={{ marginBottom: "60px" }}>
          {data && <Information info={data} />}
        </Box>
        <Box sx={{ marginBottom: "60px" }}>
          <Tabs
            runId={state.rowId}
            status={data?.status}
            downloadUrl={data?.downloadUrl}
          />
        </Box>
      </Root>
    </ThemeProvider>
  );
}

const Root = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
}));
