import axios from "axios";
const url = process.env.REACT_APP_API_ENDPOINT;

export const handlerPostStartTransferLearning = async <T,>(
  experiemntId: string,
  runId: string,
  type: string,
  data: T
) => {
  if (url) {
    const res = await axios.post(
      `${url}/experiments/${experiemntId}/transfer-learning/transfer-learning/${runId}`,
      {
        [type]: { ...data },
      },
      {
        headers: { "Content-Type": `application/json` },
      }
    );
    console.log(res);
    return res;
  }
};

export const handlerDeleteTransferLearning = async (
  experiemntId: string,
  runId: string
) => {
  if (url) {
    const res = await axios.delete(
      `${url}/experiments/${experiemntId}/transfer-learning/${runId}`
    );
    return res;
  }
};

export const handlerPauseTransferLearning = async (
  experiemntId: string,
  runId: string
) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experiemntId}/transfer-learning/${runId}/pause`
    );
    return res;
  }
};

export const handlerContinueTransferLearning = async (
  experiemntId: string,
  runId: string
) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experiemntId}/transfer-learning/${runId}/unpause`
    );
    return res;
  }
};
