import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { IModel } from "src/service/common/useModel";

export default function CustomComboBox({
  name,
  handlerFunction,
  combos,
}: {
  name: string;
  handlerFunction: (e: React.ChangeEvent<HTMLInputElement>) => void;
  combos: IModel | string[];
}) {
  const { t } = useTranslation();

  return (
    <select
      style={{ marginLeft: "20px", flex: 1 }}
      onChange={handlerFunction as ChangeEventHandler}
      name={name}
      defaultValue={Array.isArray(combos) ? combos[0] : Object.keys(combos)[0]}
    >
      {Array.isArray(combos)
        ? combos.map((el, idx) => {
            return (
              <option value={el} key={idx}>
                {t(el)}
              </option>
            );
          })
        : Object.keys(combos).map((el, idx) => {
            return (
              <option value={el} key={idx}>
                {t(el)}
              </option>
            );
          })}
    </select>
  );
}
