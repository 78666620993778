import axios from "axios";

export interface FecherGetProps {
  url: string;
  method?: "get";
  params?: any;
}

export interface IObject {
  experimentId: number;
  lifecycleStage: string;
  name: string;
}

const url = process.env.REACT_APP_API_ENDPOINT;

export const fetcher = async function <T>(
  props: FecherGetProps
): Promise<T | undefined> {
  const res = await axios({
    url: `${url}${props.url}`,
    method: "get",
    params: props.params,
  });
  return res.data as T;
};

export const fakeFetcher = ({ url, type }: { url: string; type: string }) => {
  return axios.get("https://api.github.com/repos/vercel/swr").then((res) => {
    return {};
  });
};

// 56bd7259ce50439da76555a14ac46c26
