import { createTheme } from "@mui/material";
import { koKR as coreKoKR } from "@mui/material/locale";

import "@mui/material/styles/createPalette";
declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    black: string;
    white: string;
    header: string;
  }
}

const mainBlue = "#007ab5";
const mainGreen = "#89b640";

const primaryBlue = {
  50: "#e1f5fc",
  100: "#b3e5f8",
  200: "#82d5f4",
  300: "#53c4ef",
  400: "#31b7ed",
  500: "#0faaeb",
  600: "#089ddc",
  700: "#008ac9",
  800: mainBlue,
  900: "#005993",
  1000: "#082142",
};
const primaryGreen = {
  50: "#f3f8e9",
  100: "#e0eec8",
  200: "#cce3a5",
  300: "#b8d780",
  400: "#a8cf64",
  500: "#98c648",
  600: mainGreen,
  700: "#74a236",
  800: "#608e2d",
  900: "#3e6c1c",
};

const text = {
  primary: "rgba(0, 0, 0, 0.87)",
  secondary: "rgba(0, 0, 0, 0.57)",
};

export const mainTheme = createTheme(
  {
    palette: {
      common: { black: "#000", white: "#fff", header: primaryBlue[1000] },
      primary: {
        main: primaryBlue[800],
      },
      secondary: {
        main: primaryGreen[600],
      },
      error: {
        main: "#d32f2f",
      },
    },
    typography: {
      fontFamily: [
        "Pretendard",
        '"Noto Sans Korean"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Apple SD Gothic Neo"',
        '"Malgun Gothic"',
      ].join(","),
    },
  },
  coreKoKR
);
