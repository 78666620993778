import React from "react";

interface Props {
  type: string;
}

function useColumns({ type }: Props) {
  const commonCols = [
    { field: "startTime", header: "StartTime" },
    { field: "duration", header: "Duration" },
    { field: "runName", header: "RunName" },
    { field: "runId", header: "RunId" },
    { field: "status", header: "Status" },
  ];
  let typeCols = [];
  switch (type) {
    case "od":
      typeCols = [
        { field: "trainLoss", header: "train/loss" },
        { field: "valBboxMap50", header: "val/bbox_mAP" },
        { field: "learningRate", header: "learning_rate" },
      ];
      break;
    case "ss":
      typeCols = [
        { field: "trainAuxAccSeg", header: "train/aux.acc_seg" },
        { field: "trainDecodeAccSeg", header: "train/decode.acc_seg" },
        { field: "trainLoss", header: "train/loss" },
        { field: "valMAcc", header: "val/mAcc" },
        { field: "valMIoU", header: "val/mIoU" },
        { field: "learningRate", header: "learning_rate" },
      ];
      break;
    default:
      typeCols = [
        { field: "accuracy", header: "accuracy" },
        { field: "loss", header: "loss" },
        { field: "valAccuracy", header: "val_accuracy" },
        { field: "valLoss", header: "val_loss" },
        { field: "learningRate", header: "learning_rate" },
      ];
      break;
  }
  return [...commonCols, ...typeCols];
}

export default useColumns;
