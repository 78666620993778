import { atom } from "recoil";

export interface IContent {
  isOpen: boolean;
  title: string;
  runId: string;
  extension: string;
}

export const contentOpenState = atom<IContent>({
  key: "contentOpenState",
  default: {
    isOpen: false,
    title: "",
    runId: "",
    extension: "",
  },
});
