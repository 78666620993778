import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Skeleton,
  styled,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import useFileManager from "src/service/common/useFileManager";

import { FileExplorer, IFileManagerData } from ".";
import IFileInfo from "./IFileInfo";

interface FileExplorerDialogProps {
  open: boolean;
  onClose: (
    selectedFile: string | undefined,
    e?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  title?: string;
  description?: string;
  defaultPath?: string;
  //onChangedPath: (path: string) => void;
}

export const FileExplorerDialog = ({
  title,
  description,
  open,
  onClose,
}: FileExplorerDialogProps) => {
  const [selectedDrivePath, setSelectedDrivePath] = React.useState<string>("");

  const {
    isError,
    isLoading,
    result: fileResult,
  } = useFileManager({ path: selectedDrivePath });
  const [isPending, startTransition] = React.useTransition();

  const handleClickCancelClose = () => {
    onClose(undefined);
  };
  const handleClickOkClose = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedDrivePath) {
      onClose(selectedDrivePath, e);
    } else {
      //TODO 파일이 선택되지 않음 경고!
      onClose("");
    }
  };
  const [fileManagerData, setfileManagerData] =
    React.useState<IFileManagerData>({
      currentBasePath: "",
      currentFilelist: [],
      homeDir: fileResult!.basePath,
      userDirs: {
        documents: "",
        music: "",
        pictures: "",
        videos: "",
      },
    });

  const onChangedPath = (value: string) => {
    setSelectedDrivePath(value);
  };
  React.useEffect(() => {
    if (fileResult?.directoryInfo) {
      const newFileManagerData: IFileManagerData = {
        ...fileManagerData,
        currentBasePath: selectedDrivePath,
        currentFilelist: fileResult?.directoryInfo,
      };
      setfileManagerData(newFileManagerData);
      if (fileResult.basePath !== selectedDrivePath) {
        setSelectedDrivePath(fileResult.basePath);
      }
    }
  }, [fileResult]);

  const onSelectionChangeHandler = (rows: IFileInfo[]) => {};

  return (
    <Root>
      <Dialog
        open={open}
        onClose={handleClickCancelClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle
          id="form-dialog-title"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h6">{title || "파일 선택"}</Typography>
          <Typography variant="caption">
            {description || "파일을 선택하세요"}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: 0, overflow: "hidden" }}>
          {isLoading && !isError ? (
            <Loading height={450} />
          ) : (
            fileManagerData.currentFilelist && (
              <FileExplorer
                height={450}
                onSelectionChange={onSelectionChangeHandler}
                onSelectAndDialogClose={handleClickOkClose}
                fileManagerData={fileManagerData}
                onChangedPath={onChangedPath}
                disabledLeft
              />
            )
          )}
          <Toolbar sx={{ gap: 2 }}>
            <Typography variant="body2">경로명:</Typography>
            <TextField
              sx={{ flexGrow: 2 }}
              variant="outlined"
              size="small"
              value={selectedDrivePath || ""}
              onChange={(e) => {
                startTransition(() => {
                  setSelectedDrivePath(e.target.value);
                });
              }}
            />
          </Toolbar>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={handleClickCancelClose} color="primary">
            취소
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleClickOkClose();
            }}
            color="primary"
            disabled={fileResult?.directoryInfo === undefined ? true : false}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default FileExplorerDialog;

const Root = styled("div")(({ theme }) => ({
  "& .MuiToolbar-root": {
    margin: theme.spacing(1),
    "&>*": {
      margin: theme.spacing(0.5, 1),
    },
  },
}));

const Loading = ({ height }: { height: number }) => {
  return (
    <Box sx={{ height }}>
      {[...Array(5)].map((_, idx) => (
        <Box
          key={idx}
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: "8px 0",
            "& .MuiSkeleton-root": { margin: "0 8px" },
          }}
        >
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton variant="rectangular" width="250px" height="25px" />
          <Skeleton variant="rectangular" width="150px" height="25px" />
          <Skeleton variant="rectangular" width="150px" height="25px" />
        </Box>
      ))}
    </Box>
  );
};
