import { atom } from "recoil";

interface IDialog {
  type: string;
  title: string;
  isOpen: boolean;
  handlerFunction: <T>(dataObj: T) => void;
  callbackFunction: () => void;
  value: string;
}

export const dialogOpenState = atom<IDialog>({
  key: "dialogOpenState",
  default: {
    type: "",
    title: "",
    isOpen: false,
    handlerFunction: <T>(dataObj: T) => {},
    callbackFunction: () => {},
    value: "",
  },
});
