import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IRunInfo } from "src/dto";

interface IProps {
  info: IRunInfo;
}

const Information = ({ info }: IProps) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Grid
        container
        spacing={{ sx: 2, md: 3 }}
        columns={{ sx: 4, sm: 8, md: 12 }}
      >
        {Object.keys(info).map((key, index) => {
          if (key === "downloadUrl") {
            return <></>;
          }
          return (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Box sx={{ wordBreak: "break-all;" }}>
                {t(key)} : {info[key]}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Root>
  );
};

const Root = styled("div")(({ theme }) => ({
  padding: "60px 100px 0px 100px",
}));

export default Information;
