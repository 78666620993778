import React from "react";
import { useLocation } from "react-router";
import { IParameter } from "src/dto";
import { IState } from "src/pages/Run";
import { useParameters } from "src/service/run/runService";
import CustomTable from "../common/CustomTable";

function Parameters() {
  const { state } = useLocation() as unknown as {
    state: IState;
  };
  let { data } = useParameters({ runId: state.rowId });

  const secondCol = [
    { field: "key", header: "Name", sticky: false },
    { field: "value", header: "Value", sticky: false },
  ];

  return (
    <>
      {data && (
        <CustomTable<IParameter>
          secondCol={secondCol}
          rows={data}
          isCheck={false}
          isPointer={false}
        />
      )}
    </>
  );
}

export default Parameters;
