import { Alert, AlertTitle } from "@mui/material";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { alertOpenState } from "src/recoil/alert";

export default function CustomAlert() {
  const [alertState, setAlertState] = useRecoilState(alertOpenState);

  React.useEffect(() => {
    if (alertState.isOpen) {
      let timer = setTimeout(() => {
        setAlertState({ ...alertState, isOpen: false });
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertState]);

  return (
    <>
      {alertState.isOpen && (
        <Alert
          sx={{
            position: "fixed",
            left: "50%",
            top: "40%",
            transform: "translate(-50%,-50%)",
            zIndex: 1000,
            background: "rgb(253, 237, 237);",
            border: "1px solid rgb(239, 83, 80)",
            width: "400px",
          }}
          severity="error"
          variant="outlined"
        >
          <AlertTitle>{alertState.title}</AlertTitle>
          <span>{alertState.value}</span>
        </Alert>
      )}
    </>
  );
}
