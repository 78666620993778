export const resources = {
  en: {
    translation: {
      INTERACT: "INTERACT",
      "Image \n Classification": "Image \n Classification",
      "Object \n Detection": "Object \n Detection",
      "Semantic \n Segmentation": "Semantic \n Segmentation",
      Description: "Description",
      "Learning Run": "Start Run",
      "delete run": "Delete Run",
      "Pause Learning Run": "Pause Run",
      "Continue Learning Run": "Continue Run",
      StartTime: "StartTime",
      Duration: "Duration",
      RunName: "RunName",
      Status: "Status",
      INFERENCE: "INFERENCE",
      TRANSFER: "TRANSFER",
      Delete: "delete",
      Pause: "Pause",
      Continue: "Continue",
      leaningRate: "Leaning rate",
      epoch: "Epoch",
      trainDataPath: "Train data path",
      dataRootPath: "Data root path",
      validDataPath: "Valid data path",
      dataRoot: "Data root path",
      numOfClass: "Num of class",
      imgWidth: "Image Width",
      imgHeight: "Image Height",
      batchSize: "Batch size",
      modelName: "Model",
      multiSpectral: "MultiSpectral",
      classes: "Classes",
      srcCfgPath: "Model",
      palette: "Palette",
      Parameters: "PARAMETERS",
      Metrics: "METRICS",
      Artifacts: "ARTIFACTS",
      Result: "result",
      runId: "Id",
      runName: "Name",
      startTime: "Start time",
      endTime: "End time",
      lifecycleState: "Lifecycle State",
      status: "Status",
      artifactUrl: "Artifact Url",
      sourceName: "Source name",
      fileName: "File name",
      inputData: "Input data root",
      outputData: "Output data root",
      RGB: "RGB",
      EDIT: "EDIT",
      modify: "MODIFY",
      name: "Name",
      Type: "Type",
      classification: "classification",
      objectDetection: "objectDetection",
      segmentation: "segmentation",
      "Data type": "Data type",
    },
  },
  ko: {
    translation: {
      INTERACT: "전이 학습",
      "Image \n Classification": "분류",
      "Object \n Detection": "탐지",
      "Semantic \n Segmentation": "분할",
      Description: "설명",
      "Learning Run": "학습",
      "delete run": "학습 취소",
      "Pause Learning Run": "학습 중단",
      "Continue Learning Run": "학습 재개",
      StartTime: "시작시간",
      Duration: "학습시간",
      RunName: "이름",
      Status: "상태",
      INFERENCE: "추론",
      TRANSFER: "전이학습",
      Delete: "취소",
      Pause: "중단",
      Continue: "재개",
      leaningRate: "학습률",
      epoch: "에포크",
      trainDataPath: "학습 폴더",
      validDataPath: "검증 폴더",
      dataRootPath: "데이터 폴더",
      dataRoot: "데이터 폴더",
      numOfClass: "클래스",
      imgWidth: "이미지 넓이",
      imgHeight: "이미지 높이",
      batchSize: "배치사이즈",
      modelName: "모델",
      multiSpectral: "다중분광",
      classes: "클래스",
      srcCfgPath: "모델",
      palette: "팔레트",
      Parameters: "파라미터",
      Metrics: "지표",
      Artifacts: "산출물",
      Result: "결과",
      runId: "학습 아이디",
      runName: "학습명",
      startTime: "시작 시간",
      endTime: "종료 시간",
      lifecycleState: "작동 상태",
      status: "학습 상태",
      artifactUrl: "산출물 경로",
      sourceName: "소스명",
      fileName: "파일명",
      inputData: "데이터 경로",
      outputData: "결과 데이터 경로",
      RGB: "광학",
      EDIT: "수정",
      modify: "수정",
      name: "이름",
      Type: "타입",
      classification: "분류",
      objectDetection: "탐지",
      segmentation: "분할",
      "Data type": "데이터 종류",
    },
  },
};
