import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { refreshState, systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import { useDisabledExperimentList } from "src/service/experiment/experimentService";
import useInputs from "src/service/hook/useInputs";
import { menu } from "../../config/sidebarMenu";
import ClassificationLeaning from "../learning/ClassificationLeaning";
import ObjectDetectionLearning from "../learning/ObjectDetectionLearning";
import PredictLearning from "../learning/PredictLearning";
import SegmentationLeaning from "../learning/SegmentationLeaning";
import CustomAlert from "./CustomAlert";
import CustomButton from "./CustomButton";
import CustomCategory from "./CustomCategory";
import CustomComboBox from "./CustomComboBox";
import CustomInput from "./CustomInput";
import CustomSlide from "./CustomSlide";

interface IProps {}
interface IDialogSlider {
  title: string;
  defaultValue: number;
  handlerInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMinMax?: boolean;
}

export function DialogSlider({
  title,
  defaultValue,
  handlerInput,
  isMinMax = false,
}: IDialogSlider) {
  return (
    <Box sx={{ display: "flex", width: "500px" }}>
      <Box sx={{ flex: 0.3 }}>
        <Typography align="right">{title} : </Typography>
      </Box>
      <Box sx={{ flex: 1, marginLeft: "20px" }}>
        <CustomSlide
          defaultValue={defaultValue}
          handlerInput={handlerInput}
          name={title}
          isMinMax={isMinMax}
        />
      </Box>
    </Box>
  );
}

function ExperiemntDialog({
  handlerSetData,
}: {
  handlerSetData: <T>(data: T) => void;
}) {
  const { t, i18n } = useTranslation();
  const typeArr = ["classification", "objectDetection", "segmentation"];
  const [{ name, type }, handlerInput, reset] = useInputs<{
    name: string;
    type: string;
  }>({
    name: "",
    type: typeArr[0],
  });
  React.useEffect(() => {
    handlerSetData<{
      name: string;
      type: string;
    }>({ name, type });
  }, [name, type]);

  return (
    <Box>
      <ContentBox>
        <CustomInput title={"name"} handlerFunction={handlerInput} />
      </ContentBox>
      <ContentBox sx={{ display: "flex" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("Type")} :
        </Typography>
        <CustomComboBox
          combos={typeArr}
          name={"type"}
          handlerFunction={handlerInput}
        />
      </ContentBox>
    </Box>
  );
}

function ModifyDialog({
  handlerSetData,
}: {
  handlerSetData: <T>(data: T) => void;
}) {
  const dialogState = useRecoilValue(dialogOpenState);
  const [{ name }, handlerInput, reset] = useInputs<{
    name: string;
  }>({
    name: dialogState.value,
  });

  React.useEffect(() => {
    handlerSetData<{
      name: string;
    }>({ name });
  }, [name]);

  return (
    <ContentBox>
      <CustomInput
        title={"name"}
        handlerFunction={handlerInput}
        defaultValue={dialogState.value}
        align={"center"}
      />
    </ContentBox>
  );
}

function DeleteDialog() {
  const dialogState = useRecoilValue(dialogOpenState);
  const { i18n } = useTranslation();

  return (
    <ContentBox>
      {i18n.language === "en"
        ? `Are you sure you wanna delete "${dialogState.value}" experiment ?`
        : `ID : "${dialogState.value}", 정말 삭제하시겠습니까 ?`}
    </ContentBox>
  );
}

function DisabledDialog() {
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const { data } = useDisabledExperimentList({ refresh });
  return (
    <DisabledBox>
      {" "}
      {data &&
        menu.map((el, idx) => {
          const key = el[1].toLowerCase() as systemType;

          return (
            <Box key={idx}>
              <CustomCategory
                title={el[0]}
                data={data[el[2]]}
                keyValue={key}
                disabled={true}
              />
            </Box>
          );
        })}
    </DisabledBox>
  );
}

function RestoreDialog() {
  const dialogState = useRecoilValue(dialogOpenState);
  const { i18n } = useTranslation();
  return (
    <ContentBox>
      {i18n.language === "en"
        ? `Are you sure you wanna retore "${dialogState.value}" experiment ?`
        : `Id ${dialogState.value}, 정말 북구하시겠습니까 ?`}
    </ContentBox>
  );
}

function CustomDialog({}: IProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogState, setDialogState] = useRecoilState(dialogOpenState);
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const [data, setData] = React.useState<any>({});
  const { t } = useTranslation();

  const handlerSetData = <T,>(data: T) => {
    setData(data);
    console.log(data);
  };

  const handleCancel = (): void => {
    setDialogState({
      ...dialogState,
      isOpen: false,
    });
  };
  const handlerPostApi = () => {
    dialogState.handlerFunction(data);
  };

  return (
    <Dialog fullScreen={fullScreen} open={dialogState.isOpen}>
      <DialogTitle>{t(dialogState.title)}</DialogTitle>
      <DialogContent dividers>
        {dialogState.type === "experiment" && (
          <ExperiemntDialog handlerSetData={handlerSetData} />
        )}
        {dialogState.type === "trainClassification" && (
          <Suspense>
            <ClassificationLeaning handlerSetData={handlerSetData} />
          </Suspense>
        )}
        {dialogState.type === "trainSegmentation" && (
          <Suspense>
            <SegmentationLeaning handlerSetData={handlerSetData} />
          </Suspense>
        )}
        {dialogState.type === "trainObjectDetection" && (
          <Suspense>
            <ObjectDetectionLearning handlerSetData={handlerSetData} />
          </Suspense>
        )}
        {dialogState.type === "transferLearningClassification" && (
          <Suspense>
            <ClassificationLeaning handlerSetData={handlerSetData} />
          </Suspense>
        )}
        {dialogState.type === "transferLearningSegmentation" && (
          <Suspense>
            <SegmentationLeaning handlerSetData={handlerSetData} />
          </Suspense>
        )}
        {dialogState.type === "transferLearningObjectDetection" && (
          <Suspense>
            <ObjectDetectionLearning handlerSetData={handlerSetData} />
          </Suspense>
        )}

        {(dialogState.type === "predictClassification" ||
          dialogState.type === "predictSegmentation" ||
          dialogState.type === "predictObjectDetection") && (
          <Suspense>
            <PredictLearning
              handlerSetData={handlerSetData}
              predictType={dialogState.type}
            />
          </Suspense>
        )}

        {dialogState.type === "modify" && (
          <ModifyDialog handlerSetData={handlerSetData} />
        )}
        {dialogState.type === "delete" && <DeleteDialog />}
        {dialogState.type === "restore" && <RestoreDialog />}
        {dialogState.type === "disabled" && (
          <Suspense fallback={<>Loading...</>}>
            <DisabledDialog />
          </Suspense>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton text={"Ok"} handleFunction={handlerPostApi} />
        <CustomButton text={"cancel"} handleFunction={handleCancel} />
      </DialogActions>
      <CustomAlert />
    </Dialog>
  );
}

export const ContentBox = styled(Box)(({ theme }) => ({
  width: "500px",
  marginTop: "12px",
  maxHeight: "500px",
  "& input": {
    marginLeft: "20px",
    flex: 1,
    fontSize: "13px",
  },
}));

const DisabledBox = styled(Box)(({ theme }) => ({
  width: "500px",
  marginTop: "12px",
  maxHeight: "300px",
  minHeight: "300px",
}));

export default CustomDialog;
