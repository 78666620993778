import axios from "axios";
import useSWR from "swr";
import { IData, IPostExperiment, IRes } from "../../dto/index";
import { fetcher } from "../Fetcher";

const url = process.env.REACT_APP_API_ENDPOINT;

export const useExperimentList = <T extends unknown>({
  refresh,
  name = "",
}: {
  refresh: number;
  name?: string | unknown;
}): IRes<T> => {
  const { data, error } = useSWR<T | undefined>(
    ["/experiments", refresh, name],
    async (url) => {
      let params = { name };

      if (params.name === "") {
        return await fetcher({ url });
      } else {
        return await fetcher({ url: `${url}/${name}`, params });
      }
    },
    { suspense: true }
  );
  return {
    status: 200,
    data: data !== undefined ? data : undefined,
  };
};

export const useDisabledExperimentList = ({
  refresh,
}: {
  refresh: number;
}): IRes<IData> => {
  const { data, error } = useSWR<IData | undefined>(
    ["/experiments/restore", refresh],
    async (url) => {
      return await fetcher({ url });
    },
    { suspense: true }
  );
  return {
    status: 200,
    data: data !== undefined ? data : undefined,
  };
};

export const postExperiment = async <T extends IPostExperiment>(data: T) => {
  if (url) {
    const res = await axios.post(`${url}/experiments`, data);
    return res;
  }
};

export const deleteExperiment = async (experimentId: string) => {
  if (url) {
    const res = await axios.delete(`${url}/experiments/${experimentId}`);
    return res;
  }
};

export const modifyExperiment = async <T,>(experimentId: string, name: T) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experimentId}/name`,
      name
    );
    return res;
  }
};

export const restoreExperiment = async (experimentId: string) => {
  if (url) {
    const res = await axios.post(`${url}/experiments/${experimentId}`);
    return res;
  }
};

export const modifyDescription = async (
  experimentId: number,
  params: { detectionType: string; description: string }
) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experimentId}/info`,
      params
    );
    return res;
  }
};
