import axios, { AxiosResponse } from "axios";
import {
  IArtifacts,
  IFileInfo,
  IMetricsDTO,
  IParameter,
  IRes,
  IRun,
  IRunInfo,
} from "src/dto";
import useSWR from "swr";
import { fetcher } from "../Fetcher";

const url = process.env.REACT_APP_API_ENDPOINT;

export const useRunList = ({
  experimentId,
  refresh,
}: {
  experimentId: string | undefined;
  refresh: number;
}): { data: IRun[] | undefined } => {
  //29번으로 고정되어 있습 experiemntId로 바꿔줘야함
  const { data, error } = useSWR<IRun[] | undefined>(
    [`/experiments/${experimentId}/run`, experimentId, refresh],
    async (url) => {
      return await fetcher({ url });
    },
    { suspense: true }
  );
  return {
    data,
  };
};

export const useRunInfo = ({
  experimentId,
  runId,
  refresh,
}: {
  experimentId: string | undefined;
  runId: string;
  refresh: number;
}): { data: IRunInfo | undefined } => {
  //29번으로 고정되어 있습 experiemntId, runId로 바꿔줘야함
  const { data, error } = useSWR<IRunInfo | undefined>(
    [`/experiments/${experimentId}/run/${runId}`, experimentId, runId, refresh],
    async (url) => {
      return await await fetcher({ url });
    },
    { suspense: true }
  );
  return {
    data,
  };
};

export const useParameters = ({
  runId,
}: {
  runId: string;
}): { data: IParameter[] | undefined } => {
  //29번으로 고정되어 있습 experiemntId로 바꿔줘야함
  const { data, error } = useSWR<IParameter[] | undefined>(
    [`/run/${runId}/parameters`, runId],
    async (url) => {
      return await fetcher({ url });
    },
    { suspense: true }
  );
  return {
    data,
  };
};

export const useMetrics = ({
  runId,
}: {
  runId: string;
}): { data: IMetricsDTO[] | undefined } => {
  //29번으로 고정되어 있습 experiemntId로 바꿔줘야함
  const { data, error } = useSWR<IMetricsDTO[] | undefined>(
    [`/run/${runId}/metrics`, runId],
    async (url) => {
      return await fetcher({ url });
    },
    { suspense: true }
  );
  return {
    data,
  };
};

export const useMetricsHistory = ({
  runId,
  metricKey,
}: {
  runId: string;
  metricKey: string;
}): {
  data:
    | { key: string; value: number; timestamp: string; step: number }[]
    | undefined;
} => {
  //29번으로 고정되어 있습 experiemntId로 바꿔줘야함
  const { data, error } = useSWR<
    | { key: string; value: number; timestamp: string; step: number }[]
    | undefined
  >(
    [`/run/${runId}/metrics/history`, runId, metricKey],
    async (url) => {
      let params = { metricKey };
      return await fetcher({ url, params });
    },
    { suspense: true }
  );
  return {
    data,
  };
};

export const useArtifacts = ({
  runId,
  path,
}: {
  runId: string;
  path: string;
}): IRes<IArtifacts> => {
  const { data, error } = useSWR<IArtifacts>(
    [`/run/${runId}/artifacts`, path],
    async (url) => {
      let params = { path };
      try {
        return await fetcher<IArtifacts>({ url, params });
      } catch (err: any) {
        return err.response.data;
      }
    },
    { suspense: true }
  );

  return {
    status: data?.fileInfo && data.rootUrl ? 200 : 400,
    data: data?.fileInfo && data.rootUrl ? data : undefined,
  };
};

export const useArtifactsFile = ({
  runId,
  filePath,
  extension,
}: {
  runId: string;
  filePath: string;
  extension: string;
}) => {
  // {url}/run/${runId}/artifacts/view
  const { data, error } = useSWR(
    [`${url}/run/${runId}/artifacts/view`, filePath],
    async (url) => {
      try {
        if (extension !== "png") {
          return await axios.get(url, {
            params: { filePath },
          });
        } else {
          return await axios.get(url, {
            responseType: "blob",
            params: { filePath },
          });
        }
      } catch {
        return { data: undefined };
      }
    },
    { suspense: true }
  );

  return {
    status: data !== undefined ? 200 : undefined,
    data: data !== undefined ? data : undefined,
  };
};

// export const useArtifactsFile = ({
//   runId,
//   filePath,
//   extension,
// }: {
//   runId: string;
//   filePath: string;
//   extension: string;
// }) => {
//   // ${url}/run/${runId}/artifacts/view
//   const { data, error } = useSWR(
//     [`${url}/run/${runId}/artifacts/view`, filePath],
//     async (url) => {
//       if (extension === "txt") {
//         return await axios.get(url, {
//           responseType: "text",
//           params: { filePath },
//         });
//       } else {
//         return await axios.get(url, {
//           responseType: "blob",
//           params: { filePath },
//         });
//       }
//     },
//     { suspense: true }
//   );
//   // Blob {size: 51394, type: 'image/png'} 'blob:http://localhost:3001/1710a56a-408d-4597-9eac-02edbd1d9aa2'
//   return {
//     status: data !== undefined ? 200 : undefined,
//     data: data !== undefined ? data : undefined,
//   };
// };

export const handlerPostStartTrain = async <T,>(
  experimentId: string,
  type: string,
  data: T
) => {
  if (url) {
    const res = await axios.post(
      `${url}/experiments/${experimentId}/train`,
      {
        [type]: { ...data },
      },
      {
        headers: { "Content-Type": `application/json` },
      }
    );
    return res;
  }
};

export const handlerDeleteRun = async (experimentId: string) => {
  if (url) {
    const res = await axios.delete(`${url}/experiments/${experimentId}/train`);
    return res;
  }
};

export const handlerPauseRun = async (experimentId: string) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experimentId}/train/pause`
    );
    return res;
  }
};

export const handlerContinueRun = async (experimentId: string) => {
  if (url) {
    const res = await axios.patch(
      `${url}/experiments/${experimentId}/train/unpause`
    );
    return res;
  }
};
