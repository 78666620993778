import { systemType } from "src/recoil/common";
import useSWR from "swr";
import { fetcher } from "../Fetcher";

export interface IModel {
  [index: string]: string;
}

export default function useModel({
  systemTypeState,
}: {
  systemTypeState: systemType;
}): {
  result: IModel | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, error } = useSWR<any>(
    [`/code/model`, systemTypeState],
    (url) => {
      let params = {};
      if (systemTypeState === "ic") {
        params = { type: "classification" };
      } else if (systemTypeState === "od") {
        params = { type: "objectDetection" };
      } else if (systemTypeState === "ss") {
        params = { type: "segmentation" };
      }
      return fetcher({ url, params });
    },
    { suspense: true }
  );

  return {
    result: data,
    isLoading: !error && !data,
    isError: error as boolean,
  };
}
