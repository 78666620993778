import { Box, Button, styled, Typography } from "@mui/material";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { IExperiment } from "src/dto";
import { refreshState, systemType } from "src/recoil/common";
import { modifyDescription } from "src/service/experiment/experimentService";
import useOnOff from "src/service/hook/useOnOff";
import { useSWRConfig } from "swr";
import CustomButton from "./common/CustomButton";
import ReactMarkdown from "react-markdown";

interface IProps {
  info: IExperiment;
}

const Description = ({ info }: IProps) => {
  const { isOnOff, handlerOnOff, handler } = useOnOff();
  const { t } = useTranslation();
  const systemTypeState = useRecoilValue(systemType);
  const { mutate } = useSWRConfig();
  const [refresh, setRefresh] = useRecoilState(refreshState);

  const editorRef = React.useRef<Editor>(null);
  const handlerModify = async () => {
    let type =
      systemTypeState === "ic"
        ? "classification"
        : systemTypeState === "od"
        ? "detection"
        : "segmentation";
    if (info && editorRef.current?.getInstance().getMarkdown()) {
      const res = await modifyDescription(info.experimentId, {
        detectionType: type,
        description: editorRef.current?.getInstance().getMarkdown(),
      });
      if (res?.status === 200) {
        mutate(`/experiments`);
        setRefresh(new Date().getTime());
        handler(false);
      }
    }
  };

  React.useLayoutEffect(() => {
    handler(false);
  }, [info]);

  return (
    <Root>
      <Box>
        <Typography component="span">{t("Description")}</Typography>
        <Button
          onClick={() => {
            handlerOnOff(isOnOff);
          }}
        >
          {t("Edit")}
        </Button>
      </Box>
      <Box>
        {isOnOff ? (
          <Box>
            <Editor
              initialEditType="markdown"
              initialValue={
                info.description === null ? undefined : info.description
              }
              hideModeSwitch={true}
              toolbarItems={[
                ["heading", "bold", "italic", "strike"],
                ["hr", "quote"],
                ["ul", "ol", "task", "indent", "outdent"],
                ["link"],
                ["code", "codeblock"],
              ]}
              ref={editorRef}
            />
            <Box className="buttonContainer">
              <CustomButton text={"modify"} handleFunction={handlerModify} />
            </Box>
          </Box>
        ) : info?.description === null ? (
          ""
        ) : (
          <ReactMarkdown>{info.description}</ReactMarkdown>
        )}
      </Box>
    </Root>
  );
};

const Root = styled("div")(({ theme }) => ({
  marginBottom: "60px",
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "right",
  },
}));

export default Description;
