import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  TrainClassificationReqDto,
  TrainObjectDetectionReqDto,
  TrainSegmentationReqDto,
} from "src/dto";
import { IState } from "src/pages/Run";
import { alertOpenState } from "src/recoil/alert";
import { refreshState, systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import { useSWRConfig } from "swr";
import { handlerPostStartTrain } from "../run/runService";
import { handlerPostStartTransferLearning } from "../run/transferLearningService";
import handlerValidate from "./handlerValidate";

function useLearning({
  typeArr,
  type,
  systemTypeState,
  state,
  artifactUrl,
}: {
  typeArr: string[];
  type: string;
  systemTypeState: "ic" | "od" | "ss";
  state: IState;
  artifactUrl?: string;
}) {
  const setSystemTypeState = useSetRecoilState(systemType);
  const [dialogState, setDialogState] = useRecoilState(dialogOpenState);
  const setAlertState = useSetRecoilState(alertOpenState);
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const { mutate } = useSWRConfig();

  // mutate(`experiments/${state.experimentId}/run");
  // setR`fresh(new Date().getTime());

  const handlerInteractRun = async () => {
    const runFunction =
      type === "transfer"
        ? handlerPostStartTransferLearning
        : handlerPostStartTrain;

    switch (systemTypeState) {
      case "ic":
        setDialogState({
          ...dialogState,
          title: "Interact Image Classification",
          type: typeArr[0],
          isOpen: true,
          handlerFunction: async <T,>(dataObj: T) => {
            if (
              !handlerValidate({
                value: Number(
                  (dataObj as unknown as TrainClassificationReqDto).lr
                ),
                type: "range",
                check: [0, 1],
              })
            ) {
              setAlertState({
                type: "error",
                title: "Invalid data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "Learning Rate must be in the range of [0,1]",
              });
              return;
            }
            const checkBlank = Object.keys(dataObj).filter(
              (el) =>
                (dataObj as unknown as TrainClassificationReqDto)[el] === ""
            );
            if (checkBlank.length > 0) {
              setAlertState({
                type: "error",
                title: "No data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "check it out!",
              });
              return;
            }

            if (state.experimentId !== undefined) {
              let res;
              if (type === "transfer") {
                res = await handlerPostStartTransferLearning(
                  state.experimentId,
                  state.rowId,
                  typeArr[0],
                  { ...dataObj, artifactUrl }
                );
              } else {
                res = await handlerPostStartTrain<TrainClassificationReqDto>(
                  state.experimentId,
                  typeArr[0],
                  dataObj as unknown as TrainClassificationReqDto
                );
              }
              if (res && res?.status < 300) {
                mutate(`experiments/${state.experimentId}/run`);
                setRefresh(new Date().getTime());
                setDialogState({ ...dialogState, isOpen: false });
              }
            }
          },
        });
        break;
      case "od":
        setDialogState({
          ...dialogState,
          title: "Object Detection",
          type: typeArr[1],
          isOpen: true,
          handlerFunction: async <T,>(dataObj: T) => {
            if (
              !handlerValidate({
                value: Number(
                  (dataObj as unknown as TrainObjectDetectionReqDto).lr
                ),
                type: "range",
                check: [0, 1],
              })
            ) {
              setAlertState({
                type: "error",
                title: "Invalid data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "Learning Rate must be in the range of [0,1]",
              });
              return;
            }

            const checkBlank = Object.keys(dataObj).filter(
              (el) =>
                (dataObj as unknown as TrainObjectDetectionReqDto)[el] === "" ||
                (dataObj as unknown as TrainObjectDetectionReqDto)[el] === 0
            );
            if (checkBlank.length > 0) {
              setAlertState({
                type: "error",
                title: "No data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "check it out!",
              });
              return;
            }
            if (state.experimentId !== undefined) {
              let res;
              if (type === "transfer") {
                res = await handlerPostStartTransferLearning(
                  state.experimentId,
                  state.rowId,
                  typeArr[1],
                  { ...dataObj, artifactUrl }
                );
              } else {
                res = await handlerPostStartTrain<TrainClassificationReqDto>(
                  state.experimentId,
                  typeArr[1],
                  dataObj as unknown as TrainClassificationReqDto
                );
              }

              if (res && res?.status < 300) {
                mutate(`experiments/${state.experimentId}/run`);
                setRefresh(new Date().getTime());
                setDialogState({ ...dialogState, isOpen: false });
              }
            }
          },
        });
        break;
      default:
        setDialogState({
          ...dialogState,
          title: "Semantic Segmentation",
          type: typeArr[2],
          isOpen: true,
          handlerFunction: async <T,>(dataObj: T) => {
            console.log(dataObj);
            if (
              !handlerValidate({
                value: Number(
                  (dataObj as unknown as TrainSegmentationReqDto).lr
                ),
                type: "range",
                check: [0, 1],
              })
            ) {
              setAlertState({
                type: "error",
                title: "Invalid data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "Learning Rate must be in the range of [0,1]",
              });
              return;
            }
            const checkBlank = Object.keys(dataObj).filter(
              (el) => (dataObj as unknown as TrainSegmentationReqDto)[el] === ""
            );
            if (checkBlank.length > 0) {
              setAlertState({
                type: "error",
                title: "No data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "check it out!",
              });
              return;
            }

            if (
              (dataObj as unknown as TrainSegmentationReqDto)["palette"]
                .length !==
              (dataObj as unknown as TrainSegmentationReqDto)["classes"].length
            ) {
              setAlertState({
                type: "error",
                title: `Palette : ${
                  (dataObj as unknown as TrainSegmentationReqDto)["palette"]
                    .length
                } Class : ${
                  (dataObj as unknown as TrainSegmentationReqDto)["classes"]
                    .length
                },Don't match count`,
                isOpen: true,
                handlerFunction: () => {},
                value: "check it out!",
              });
              return;
            }

            if (state.experimentId !== undefined) {
              let res;
              if (type === "transfer") {
                res = await handlerPostStartTransferLearning(
                  state.experimentId,
                  state.rowId,
                  typeArr[2],
                  { ...dataObj, artifactUrl }
                );
              } else {
                res = await handlerPostStartTrain<TrainClassificationReqDto>(
                  state.experimentId,
                  typeArr[2],
                  dataObj as unknown as TrainClassificationReqDto
                );
              }

              if (res && res?.status < 300) {
                mutate(`experiments/${state.experimentId}/run`);
                setRefresh(new Date().getTime());
                setDialogState({ ...dialogState, isOpen: false });
              }
            }
          },
        });
        break;
    }
  };
  return handlerInteractRun;
}

export default useLearning;
