import { ConstructionOutlined } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Divider,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IExperiment } from "src/dto";
import { refreshState, systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import {
  deleteExperiment,
  modifyExperiment,
  restoreExperiment,
} from "src/service/experiment/experimentService";
import { mutate } from "swr";
import RestoreIcon from "@mui/icons-material/Restore";
import { alertOpenState } from "src/recoil/alert";

interface ICategory {
  title: string;
  data: IExperiment[]; //우선은 any타입 요청 후 데이터 확인하고 타입 넣기
  keyValue: systemType;
  handlerNavigate?: (id: string, name: { name: string }) => void;
  disabled?: boolean;
}

const MuiAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "0px",
  borderRadius: "0px",
  boxShadow: "0px",
  "&:not(:last-child)": {
    borderBottom: 12,
  },
  "&:before": {
    display: "none",
  },
}));

function CustomCategory({
  title,
  data,
  keyValue,
  handlerNavigate = () => {},
  disabled = false,
}: ICategory) {
  const setSystemType = useSetRecoilState(systemType);
  const [dialogState, setDialogState] = useRecoilState(dialogOpenState);
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const { t, i18n } = useTranslation();
  const setAlertState = useSetRecoilState(alertOpenState);

  const handlerSetSystemType = (type: systemType) => {
    setSystemType(type);
  };
  const handlerModifyExperiment = (experimentId: string, name: string) => {
    setDialogState({
      ...dialogState,
      type: "modify",
      title: "Modify Experiment Title",
      isOpen: true,
      handlerFunction: async <T,>(dataObj: T) => {
        if ((dataObj as unknown as { name: string }).name === "") {
          setAlertState({
            type: "Error",
            title:
              i18n.language === "en"
                ? `Name field is blank`
                : "이름 값이 없습니다.",
            isOpen: true,
            handlerFunction: () => {},
            value:
              i18n.language === "en"
                ? `Please fill out name field`
                : "이름 값을 넣어주세요",
          });
          return;
        }
        const res = await modifyExperiment(experimentId, dataObj).catch(
          (err) => {
            console.log(err);
            setAlertState({
              type: "Error",
              title:
                i18n.language === "en"
                  ? `Exist duplicated experiment`
                  : "중복 이름이 존재합니다.",
              isOpen: true,
              handlerFunction: () => {},
              value:
                i18n.language === "en"
                  ? `Please change another name`
                  : "다른 이름을 넣어주세요.",
            });
            return;
          }
        );
        if (res?.status === 200) {
          mutate("experiments");
          setRefresh(new Date().getTime());
          setDialogState({ ...dialogState, isOpen: false });
        }
      },
      callbackFunction: () => {},
      value: name,
    });
  };

  const handlerDeleteExperiment = (value: string, name: string) => {
    setDialogState({
      ...dialogState,
      type: "delete",
      title: "Delete Experiment",
      isOpen: true,
      handlerFunction: async () => {
        const res = await deleteExperiment(`${value}`).catch((err) => {
          setAlertState({
            type: "Error",
            title:
              i18n.language === "en"
                ? `Occur unexpectable error`
                : "알 수 없는 에러가 발생했습니다.",
            isOpen: true,
            handlerFunction: () => {},
            value:
              i18n.language === "en"
                ? `Please try again`
                : "잠시후 다시 시도해주세요",
          });
          return;
        });
        if (res?.status === 200) {
          mutate("experiments");
          setRefresh(new Date().getTime());
          setDialogState({ ...dialogState, isOpen: false });
        }
      },
      callbackFunction: () => {},
      value: name,
    });
  };

  const handlerRestoreExperiment = (experimentId: string, name: string) => {
    setDialogState({
      ...dialogState,
      type: "restore",
      title: "Restore Experiment",
      isOpen: true,
      handlerFunction: async () => {
        const res = await restoreExperiment(experimentId).catch((err) => {
          setAlertState({
            type: "Error",
            title:
              i18n.language === "en"
                ? `Occur unexpectable error`
                : "알 수 없는 에러가 발생했습니다.",
            isOpen: true,
            handlerFunction: () => {},
            value:
              i18n.language === "en"
                ? `Please try again`
                : "잠시후 다시 시도해주세요",
          });
          return;
        });
        if (res?.status === 201) {
          mutate("experiments");
          setRefresh(new Date().getTime());
        }
        setDialogState({ ...dialogState, isOpen: false });
      },
      callbackFunction: () => {},
      value: name,
    });
  };

  return (
    <MuiAccordion defaultExpanded={!disabled}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon className="MuiArrowCategory" />}
      >
        <Typography variant="h6">{t(title)}</Typography>
      </AccordionSummary>
      <Divider light />
      <AccordionDetails>
        <CustomUlTag disabled={disabled}>
          {data.map((el: any, idx: number) => {
            return (
              <li className={disabled ? "disabled" : ""} key={idx}>
                <Box
                  className="link"
                  onClick={() => {
                    handlerSetSystemType(keyValue);
                    const name: { name: string } = { name: el.name };
                    handlerNavigate(el.experimentId, name);
                  }}
                >
                  {el.name}
                </Box>
                <Box className="boxContainer">
                  {!disabled ? (
                    <>
                      <CreateOutlinedIcon
                        className={"svg"}
                        onClick={() => {
                          handlerModifyExperiment(el.experimentId, el.name);
                        }}
                      />
                      <DeleteForeverOutlinedIcon
                        className={"svg"}
                        onClick={() => {
                          handlerDeleteExperiment(el.experimentId, el.name);
                        }}
                      />{" "}
                    </>
                  ) : (
                    <>
                      <RestoreIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          handlerRestoreExperiment(el.experimentId, el.name);
                        }}
                      />
                    </>
                  )}
                </Box>
              </li>
            );
          })}
          {data === undefined && <Skeleton variant="text" animation="wave" />}
        </CustomUlTag>
      </AccordionDetails>
    </MuiAccordion>
  );
}

type UlProps = {
  disabled: boolean;
};

const CustomUlTag = styled("ul")<UlProps>(({ theme, disabled }) => {
  return {
    "& .disabled": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& :hover": disabled
      ? {
          background: "#d3d3d3",
        }
      : {},
  };
});

export default CustomCategory;
