import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Box, Divider, styled } from "@mui/material";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useRecoilValue } from "recoil";
import { contentOpenState } from "src/recoil/artifacts";
import { useArtifactsFile } from "src/service/run/runService";
interface IProps {}

function ContentTitle({ title }: { title: string }) {
  return (
    <Box sx={{ height: "50px", paddingTop: "12px", paddingLeft: "24px" }}>
      {title}
    </Box>
  );
}

function Content({}: IProps) {
  const [imgUrl, setImgUrl] = React.useState("");

  const content = useRecoilValue(contentOpenState);
  const { data, status } = useArtifactsFile({
    runId: content.runId,
    filePath: content.title,
    extension: content.extension,
  });
  console.log(data);
  React.useEffect(() => {
    if (
      data?.data &&
      status &&
      status < 400 &&
      data.data &&
      content.extension === "png"
    ) {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(data.data);
      setImgUrl(imageUrl);
    }
  }, [data]);

  return (
    <Root>
      <div>
        <ContentTitle title={content.title} />
      </div>
      <Divider />
      <div className="textContainer">
        {data && content.extension !== "png" && data.data !== undefined && (
          <SyntaxHighlighter
            language={content.extension}
            children={data.data}
          />
        )}
        {/* <pre>{data.data}</pre> */}

        {data && content.extension === "png" && <img src={`${imgUrl}`} />}
        {data && data.data === undefined && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <WarningAmberRoundedIcon />
            File is too large to preview
          </Box>
        )}
      </div>
    </Root>
  );
}

const Root = styled("div")(({ theme }) => ({
  height: "100%",

  "& img": {
    height: "500px",
    width: "100%",
  },
  "& pre": {
    color: `black`,
    fontFamily: `"Source Code Pro", Menlo, monospace;`,
    textAlign: `left`,
    whiteSpace: `pre`,
    wordSpacing: `normal`,
    wordBreak: `normal`,
    overflowWrap: `normal`,
    lineHeight: `1.5`,
    tabSize: `4`,
    hyphens: `none`,
    position: `relative`,
    margin: `0.5em 0px 1em`,
    overflow: `visible`,
    padding: `0px`,
    boxSizing: `border-box`,
    fontSize: `13px`,
  },
  "& .textContainer": {
    height: "100%",
  },
}));

export default Content;
