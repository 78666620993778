import styled from "@emotion/styled";
import { Box, Divider, Paper, ThemeProvider } from "@mui/material";
import React, { Suspense } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { mainTheme } from "src/config/theme";
import { IArtifacts } from "src/dto";
import { contentOpenState } from "src/recoil/artifacts";
import { useArtifacts } from "src/service/run/runService";
import Content from "../common/Content";
import Tree from "../Tree";

interface IProps {
  runId: string;
}

function Artifacts({ runId }: IProps) {
  const { data, status, error } = useArtifacts({ runId, path: "" });
  const [content, setContent] = useRecoilState(contentOpenState);
  React.useEffect(() => {
    setContent({
      ...content,
      isOpen: false,
    });
  }, []);
  return (
    <ThemeProvider theme={mainTheme}>
      {data ? (
        <Root>
          <TreeContainer>{data && <Tree data={data.fileInfo} />}</TreeContainer>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ width: "calc(100% - 180px)" }}>
            {content.isOpen ? (
              <Suspense fallback={<>Loading...</>}>
                <Content />
              </Suspense>
            ) : (
              <div style={{ width: "100%" }}></div>
            )}
          </Box>
        </Root>
      ) : (
        <Root>
          <TreeContainer>{data && <Tree data={[]} />}</TreeContainer>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              width: "calc(100% - 180px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <strong>There is no artifacts</strong>
          </Box>
        </Root>
      )}
    </ThemeProvider>
  );
}

const Root = styled(Paper)(({ theme }) => ({
  display: "flex",
}));

const TreeContainer = styled(Box)(({ theme }) => ({
  width: "180px",
  minWidth: "220px",
  height: "700px",
  overflow: "auto",
}));

export default Artifacts;
