import { Button } from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { mainTheme } from "src/config/theme";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import "moment/locale/he";
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/en-gb";

export default function Header() {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const handlerChangeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ko");
      moment.locale("ko");
    } else {
      i18n.changeLanguage("en");
      moment.locale("en-gb");
    }
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Root>
        <img
          onClick={() => {
            navigate("/");
          }}
          src="../../all4land_CI_en_bl.png"
        />
        {i18n.language === "en" ? (
          <ReactCountryFlag
            style={{
              fontSize: "1.5em",
              lineHeight: "1.5em",
            }}
            className="internationalization"
            countryCode="KR"
            onClick={handlerChangeLanguage}
          />
        ) : (
          <ReactCountryFlag
            style={{
              fontSize: "1.5em",
              lineHeight: "1.5em",
            }}
            className="internationalization"
            countryCode="US"
            onClick={handlerChangeLanguage}
          />
        )}
      </Root>
    </ThemeProvider>
  );
}
const Root = styled("header")(({ theme }) => {
  return {
    height: "100px",
    background: theme.palette.common.header,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",

    "& img": {
      cursor: "pointer",
      width: "260px",
      marginLeft: "30px",
    },
    "& .internationalization": {
      cursor: "pointer",
      marginRight: "30px",
    },
  };
});
