import React from "react";

interface IProps {
  value: string | number;
  type: "range" | "equal";
  check: string | number[];
}

function handlerValidate({ value, type, check }: IProps) {
  if (type === "range") {
    if (value < check[0]) {
      return false;
    }
    if (value > check[1]) {
      return false;
    }
    return true;
  } else if (type === "equal") {
    if (value !== check) {
      return false;
    }
    return true;
  }
}

export default handlerValidate;
