import { Box, Paper, styled, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Experiments from "./pages/Experiments";
import Run from "./pages/Run";
import { RecoilRoot } from "recoil";
import CustomDialog from "./components/common/CustomDialog";
import { Suspense } from "react";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { alertOpenState } from "./recoil/alert";
import CustomAlert from "./components/common/CustomAlert";
const licenseKey = process.env.REACT_APP_MUI_LICENSEKEY;

function App() {
  LicenseInfo.setLicenseKey(licenseKey!);

  return (
    <RecoilRoot>
      <BrowserRouter>
        <Header />
        <Box
          component="main"
          sx={{ display: "flex", minHeight: "calc(100vh - 100px)" }}
        >
          <Routes>
            {/* root page 만들어야해요 */}
            <Route
              element={
                <Suspense>
                  <Sidebar />
                </Suspense>
              }
            >
              <Route path="/" element={<div>Experiemnt</div>}></Route>
              <Route
                path="/:experimentId"
                element={
                  <Suspense>
                    <Experiments />
                  </Suspense>
                }
              ></Route>
              <Route path="*" element={<div>Not Found</div>}></Route>
            </Route>
            <Route
              path="/run/:runId"
              element={
                <Suspense>
                  <Run />
                </Suspense>
              }
            ></Route>
          </Routes>
        </Box>
      </BrowserRouter>
      <CustomDialog />
    </RecoilRoot>
  );
}

const Main = styled(Paper)(({ theme }) => {
  return {};
});

export default App;
