import { useState, useCallback } from "react";

function useInputs<T>(
  initialForm: T
): [
  T,
  (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    inputName?: string,
    slideVal?: number,
    urlPath?: string
  ) => void,
  () => void
] {
  const [form, setForm] = useState(initialForm);

  const handlerInput = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement> | undefined,
      inputName?: string,
      slideVal?: number,
      urlPath?: string
    ): void => {
      const { name, value } =
        slideVal && inputName
          ? { name: inputName, value: slideVal }
          : inputName && urlPath
          ? { name: inputName, value: urlPath }
          : e !== undefined
          ? e.target
          : { name: "", value: "" };
      console.log(name, value);

      setForm((form) => ({ ...form, [name]: value }));
    },
    []
  );
  const reset = useCallback(() => setForm(initialForm), [initialForm]);
  return [form, handlerInput, reset];
}

export default useInputs;
