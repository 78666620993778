import { Box, Skeleton, Switch, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { TrainClassificationReqDto } from "src/dto";
import { systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import useModel from "src/service/common/useModel";
import useInputs from "src/service/hook/useInputs";
import CustomComboBox from "../common/CustomComboBox";
import { ContentBox, DialogSlider } from "../common/CustomDialog";
import CustomInput from "../common/CustomInput";
import { FileExplorerDialog } from "../fileExplorer";

function ClassificationLeaning({
  handlerSetData,
}: {
  handlerSetData: <T>(data: T) => void;
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [urlKey, setUrl] = React.useState("");
  const [isMultiSpectralVal, setIsMultiSpectralVal] = React.useState(false);

  const systemTypeState = useRecoilValue(systemType);
  const { result } = useModel({ systemTypeState });

  const fileDialogOpenHandler = (title: string) => {
    setUrl((prev) => title);
    setIsOpen(true);
  };
  const options = [
    { title: "numOfClass", defaultValue: 1, isMaxMin: false },
    { title: "imgWidth", defaultValue: 512, isMaxMin: true },
    { title: "imgHeight", defaultValue: 512, isMaxMin: true },
    { title: "batchSize", defaultValue: 4, isMaxMin: false },
  ];
  const [
    {
      lr,
      epoch,
      trainDataPath,
      validDataPath,
      modelName,
      numOfClass,
      imgWidth,
      imgHeight,
      batchSize,
      isMultiSpectral,
    },
    handlerInput,
    reset,
  ] = useInputs<TrainClassificationReqDto>({
    lr: 0.0001,
    epoch: 100,
    trainDataPath: "",
    validDataPath: "",
    modelName: Object.keys(result!)[0],
    numOfClass: 1,
    imgWidth: 512,
    imgHeight: 512,
    batchSize: 4,
    isMultiSpectral: isMultiSpectralVal,
  });

  React.useEffect(() => {
    handlerSetData<TrainClassificationReqDto>({
      lr: Number(lr),
      epoch: Number(epoch),
      trainDataPath,
      validDataPath,
      modelName,
      numOfClass: Number(numOfClass),
      imgWidth: Number(imgWidth),
      imgHeight: Number(imgHeight),
      batchSize: Number(batchSize),
      isMultiSpectral: isMultiSpectralVal,
    });
  }, [
    lr,
    epoch,
    trainDataPath,
    validDataPath,
    modelName,
    numOfClass,
    imgWidth,
    imgHeight,
    batchSize,
    isMultiSpectralVal,
  ]);

  return (
    <Box>
      <ContentBox>
        <CustomInput
          title={"lr"}
          valueType="number"
          handlerFunction={handlerInput}
          defaultValue={lr}
        />
      </ContentBox>
      <ContentBox>
        <CustomInput
          title={"epoch"}
          valueType="number"
          handlerFunction={handlerInput}
          defaultValue={epoch}
        />
      </ContentBox>
      <ContentBox>
        <CustomInput
          title={"trainDataPath"}
          handlerFunction={handlerInput}
          type={"box"}
          inputClickFunction={fileDialogOpenHandler}
          path={trainDataPath}
        />
      </ContentBox>
      <ContentBox>
        <CustomInput
          title={"validDataPath"}
          handlerFunction={handlerInput}
          type={"box"}
          inputClickFunction={fileDialogOpenHandler}
          path={validDataPath}
        />
      </ContentBox>
      {options.map((el, key) => (
        // <DialogSlider
        //   title={t(el.title)}
        //   defaultValue={el.defaultValue}
        //   key={key}
        //   handlerInput={handlerInput}
        //   isMinMax={el.isMaxMin}
        // />
        <ContentBox>
          <CustomInput
            title={el.title}
            key={key}
            handlerFunction={handlerInput}
            defaultValue={el.defaultValue}
          />
        </ContentBox>
      ))}
      <ContentBox sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("modelName")} :
        </Typography>
        {result ? (
          <CustomComboBox
            combos={result}
            name="modelName"
            handlerFunction={handlerInput}
          />
        ) : (
          <Box sx={{ marginLeft: "20px", flex: 1 }}>
            <Skeleton variant="text" animation={false} />
          </Box>
        )}
      </ContentBox>
      <ContentBox sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("multiSpectral")} :
        </Typography>
        <Box sx={{ marginLeft: "20px", flex: "1" }}>
          <Switch
            onChange={(_e, check) => {
              setIsMultiSpectralVal(check);
            }}
          />
        </Box>
      </ContentBox>
      {isOpen && (
        <Suspense>
          <FileExplorerDialog
            title="서버 스토리지 내부 경로 선택"
            description="FTP를 통해 미리 업로드된 파일이 있는 경로(폴더)를 선택합니다."
            open={isOpen}
            onClose={(selectedPath: string | undefined, e) => {
              if (selectedPath) {
                handlerInput(e, urlKey, undefined, selectedPath);
              }
              setIsOpen(false);
            }}
            defaultPath={""}
          />
        </Suspense>
      )}
    </Box>
  );
}

export default ClassificationLeaning;
