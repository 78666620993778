import { useRecoilState, useSetRecoilState } from "recoil";
import { TrainObjectDetectionReqDto, TrainSegmentationReqDto } from "src/dto";
import { IState } from "src/pages/Run";
import { alertOpenState } from "src/recoil/alert";
import { refreshState, systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import { useSWRConfig } from "swr";
import {
  handlerPostStartPredict,
  PredictClassificationReqDto,
  PredictObjectDetectionReqDto,
  PredictSegmentationReqDto,
} from "../run/predictService";

function usePredict({
  typeArr,
  systemTypeState,
  state,
  artifactUrl,
}: {
  typeArr: string[];
  systemTypeState: "ic" | "od" | "ss";
  state: IState;
  artifactUrl?: string;
}) {
  const setSystemTypeState = useSetRecoilState(systemType);
  const [dialogState, setDialogState] = useRecoilState(dialogOpenState);
  const setAlertState = useSetRecoilState(alertOpenState);
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const { mutate } = useSWRConfig();

  const handlerReasoningRun = async () => {
    switch (systemTypeState) {
      case "ic":
        setDialogState({
          ...dialogState,
          title: "Inference Image Classification",
          type: typeArr[0],
          isOpen: true,
          value: artifactUrl!,
          handlerFunction: async <T,>(dataObj: T) => {
            if (
              (dataObj as unknown as PredictClassificationReqDto)[
                "multiSpectral"
              ] === "multispectral"
            ) {
              delete (dataObj as unknown as PredictClassificationReqDto)
                .outputFileName;
            }
            const checkBlank = Object.keys(dataObj).filter(
              (el) =>
                (dataObj as unknown as PredictClassificationReqDto)[el] === ""
            );
            if (checkBlank.length > 0) {
              setAlertState({
                type: "error",
                title: "No data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "check it out!",
              });
              return;
            }

            if (state.experimentId !== undefined) {
              const res =
                await handlerPostStartPredict<PredictClassificationReqDto>({
                  experiemntId: state.experimentId,
                  runId: state.rowId,
                  type: typeArr[0],
                  data: dataObj as unknown as PredictClassificationReqDto,
                });
              if (res && res?.status < 300) {
                mutate(`experiments/${state.experimentId}/run`);
                setRefresh(new Date().getTime());
                setDialogState({ ...dialogState, isOpen: false });
              }
            }
          },
        });
        break;

      case "od":
        setDialogState({
          ...dialogState,
          title: "Inference Object Detection",
          type: typeArr[1],
          isOpen: true,
          value: artifactUrl!,
          handlerFunction: async <T,>(dataObj: T) => {
            delete (dataObj as unknown as PredictObjectDetectionReqDto)[
              "multiSpectral"
            ];
            const checkBlank = Object.keys(dataObj).filter(
              (el) =>
                (dataObj as unknown as PredictObjectDetectionReqDto)[el] === ""
            );
            if (checkBlank.length > 0) {
              setAlertState({
                type: "error",
                title: "No data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "check it out!",
              });
              return;
            }
            if (state.experimentId !== undefined) {
              const res =
                await handlerPostStartPredict<PredictObjectDetectionReqDto>({
                  experiemntId: state.experimentId,
                  runId: state.rowId,
                  type: typeArr[1],
                  data: dataObj as unknown as PredictObjectDetectionReqDto,
                });

              if (res && res?.status < 300) {
                mutate(`experiments/${state.experimentId}/run`);
                setRefresh(new Date().getTime());
                setDialogState({ ...dialogState, isOpen: false });
              }
            }
          },
        });
        break;

      default:
        setDialogState({
          ...dialogState,
          title: "Inference Semantic Segmentation",
          type: typeArr[2],
          isOpen: true,
          value: artifactUrl!,
          handlerFunction: async <T,>(dataObj: T) => {
            delete (dataObj as unknown as PredictObjectDetectionReqDto)[
              "multiSpectral"
            ];
            const checkBlank = Object.keys(dataObj).filter(
              (el) =>
                (dataObj as unknown as PredictSegmentationReqDto)[el] === ""
            );
            if (checkBlank.length > 0) {
              setAlertState({
                type: "error",
                title: "No data !",
                isOpen: true,
                handlerFunction: () => {},
                value: "check it out!",
              });
              return;
            }
            if (state.experimentId !== undefined) {
              const res =
                await handlerPostStartPredict<PredictSegmentationReqDto>({
                  experiemntId: state.experimentId,
                  runId: state.rowId,
                  type: typeArr[2],
                  data: dataObj as unknown as PredictSegmentationReqDto,
                });

              if (res && res?.status < 300) {
                mutate(`experiments/${state.experimentId}/run`);
                setRefresh(new Date().getTime());
                setDialogState({ ...dialogState, isOpen: false });
              }
            }
          },
        });
        break;
    }
  };
  return handlerReasoningRun;
}

export default usePredict;
