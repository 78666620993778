import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import prettyBytes from "pretty-bytes";
import React from "react";
import IFileInfo from "../IFileInfo";
import IconByRow from "./IconByRow";

interface EnhancedTableProps {
  rows: IFileInfo[];
  onResourceItemDoubleClick?: (
    event: React.MouseEvent<HTMLElement>,
    idx: number,
    row: IFileInfo
  ) => void;
  onSelectionChange?: (rows: IFileInfo[]) => void;
}

const EnhacedTable = ({
  rows,
  onResourceItemDoubleClick,
  onSelectionChange,
}: EnhancedTableProps): React.ReactElement => {
  const coloums: GridColumns = [
    // { field: "id", headerName: "#" },
    {
      field: "name",
      headerName: "이름",
      flex: 0.09,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconByRow fileInfo={params.row} />
          {params.value}
        </>
      ),
    },
    {
      field: "size",
      headerName: "파일 크기",
      flex: 0.05,
      renderCell: (params: GridRenderCellParams) =>
        prettyBytes(typeof params.value === "number" ? params.value : 0),
    },
    {
      field: "ext",
      headerName: "파일 유형",
      flex: 0.05,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value === ".JPG" || params.value === ".JPEG") {
          return "JPEG 이미지 파일";
        } else if (params.value === ".PNG") {
          return "PNG 이미지 파일";
        } else if (params.value === ".TIF") {
          return "TIF 이미지 파일";
        } else if (params.value === ".TIFF") {
          return "TIFF 이미지 파일";
        } else if (params.getValue(params.id, "isDirectory")) {
          return "폴더";
        } else {
          return params.value;
        }
      },
    },
    {
      field: "updateDate",
      headerName: "수정 일자",
      flex: 0.05,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? new Date(params.value).toLocaleString() : "-",
    },
  ];

  return (
    <DataGridPro
      columns={coloums}
      rows={rows}
      pagination
      headerHeight={42}
      disableSelectionOnClick
      sx={{
        "& .MuiDataGrid-row:hover": {
          color: "primary.main",
        },
        "& .MuiDataGrid-cell:hover": {
          cursor: "pointer",
        },
      }}
      onRowDoubleClick={(params: any, event: any) => {
        onResourceItemDoubleClick &&
          onResourceItemDoubleClick(
            event,
            params?.id as number,
            params.row as IFileInfo
          );
      }}
    />
  );
};

export default EnhacedTable;
