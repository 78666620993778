import { atom } from "recoil";

export type systemType = "ss" | "od" | "ic";

export const refreshState = atom<number>({
  key: "refreshState",
  default: 0,
});

export const systemType = atom<systemType>({
  key: "systemType",
  default: "ic",
});

export const editState = atom<boolean>({
  key: "editState",
  default: false,
});

export const modelState = atom<string[]>({
  key: "modelState",
  default: [
    "DenseNet121",
    "DenseNet169",
    "DenseNet201",
    "EfficientNetB0",
    "EfficientNetB1",
    "EfficientNetB2",
    "EfficientNetB3",
    "EfficientNetB4",
    "EfficientNetB5",
    "EfficientNetB6",
    "EfficientNetB7",
    "InceptionResNetV2",
    "InceptionV3",
    "MobileNet",
    "MobileNetV2",
    "MobileNetV3Large",
    "MobileNetV3Small",
    "NASNetLarge",
    "NASNetMobile",
    "ResNet101",
    "ResNet101V2",
    "ResNet152",
    "ResNet152V2",
    "ResNet50",
    "ResNet50V2",
    "VGG16",
    "VGG19",
    "Xception",
  ],
});
