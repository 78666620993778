import { Box, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TrainObjectDetectionReqDto } from "src/dto";
import { systemType } from "src/recoil/common";
import useModel from "src/service/common/useModel";
import useInputs from "src/service/hook/useInputs";
import CustomComboBox from "../common/CustomComboBox";
import { ContentBox } from "../common/CustomDialog";
import CustomInput from "../common/CustomInput";
import { FileExplorerDialog } from "../fileExplorer";
import CloseIcon from "@mui/icons-material/Close";
import { InputWrapper } from "../common/CustomAutoComplete";

function ObjectDetectionLearning({
  handlerSetData,
}: {
  handlerSetData: <T>(data: T) => void;
}) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [urlKey, setUrl] = React.useState("");
  const systemTypeState = useRecoilValue(systemType);
  const { result } = useModel({ systemTypeState });
  const [classesTags, setClassesTags] = React.useState<string[]>([]);

  const fileDialogOpenHandler = (title: string) => {
    setUrl((prev) => title);
    setIsOpen(true);
  };
  const [
    { lr, srcCfgPath, epoch, dataRootPath, classes },
    handlerInput,
    reset,
  ] = useInputs<TrainObjectDetectionReqDto>({
    lr: 0.0025,
    epoch: 50,
    dataRootPath: "",
    classes: "",
    srcCfgPath: Object.keys(result!)[0],
  });

  React.useEffect(() => {
    if (result) {
      handlerSetData<TrainObjectDetectionReqDto>({
        lr: Number(lr),
        srcCfgPath,
        epoch: Number(epoch),
        dataRootPath,
        classes: String(classesTags),
      });
    }
  }, [lr, srcCfgPath, epoch, dataRootPath, classes, classesTags]);

  return (
    <Box>
      <ContentBox>
        <CustomInput
          valueType="number"
          title={"lr"}
          handlerFunction={handlerInput}
          defaultValue={lr}
        />
      </ContentBox>
      <ContentBox>
        <CustomInput
          valueType="number"
          title={"epoch"}
          handlerFunction={handlerInput}
          defaultValue={epoch}
        />
      </ContentBox>
      <ContentBox>
        <CustomInput
          title={"dataRootPath"}
          handlerFunction={handlerInput}
          type={"box"}
          inputClickFunction={fileDialogOpenHandler}
          path={dataRootPath}
        />
      </ContentBox>

      {/* <ContentBox>
        <CustomInput title={"classes"} handlerFunction={handlerInput} />
      </ContentBox> */}
      <ContentBox sx={{ display: "flex" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("classes")} :
        </Typography>
        <InputWrapper
          sx={{
            borderColor: "rgb(118, 118, 118)",
            flex: 1,
            marginLeft: "20px",
          }}
        >
          {classesTags.map((el: string, idx: number) => (
            <div className="StyledCustomTag" key={idx}>
              <span>{el}</span>
              <CloseIcon
                onClick={() => {
                  setClassesTags((prev) => prev.filter((name) => name !== el));
                }}
              />
            </div>
          ))}
          {
            <input
              name={"classes"}
              placeholder="please press enter key to put in classes field"
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  const tags = e.currentTarget.value.split(",")[0];
                  setClassesTags((prev) => {
                    return [...prev, tags];
                  });
                  e.currentTarget.value = "";
                }
              }}
            />
          }
        </InputWrapper>
      </ContentBox>
      <ContentBox sx={{ display: "flex" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("srcCfgPath")} :
        </Typography>
        {result && (
          <CustomComboBox
            combos={result}
            name="srcCfgPath"
            handlerFunction={handlerInput}
          />
        )}
      </ContentBox>
      {isOpen && (
        <Suspense>
          <FileExplorerDialog
            title="서버 스토리지 내부 경로 선택"
            description="FTP를 통해 미리 업로드된 파일이 있는 경로(폴더)를 선택합니다."
            open={isOpen}
            onClose={(selectedPath: string | undefined, e) => {
              if (selectedPath) {
                handlerInput(e, urlKey, undefined, selectedPath);
              }
              setIsOpen(false);
            }}
          />
        </Suspense>
      )}
    </Box>
  );
}

export default ObjectDetectionLearning;
