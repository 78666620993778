import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from "@mui/material";
import moment from "moment";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { mainTheme } from "src/config/theme";
import { systemType } from "src/recoil/common";
import useOnOff from "src/service/hook/useOnOff";
import Chart from "../chart/Chart";
import ChartController from "../chart/ChartController";

interface ICol {
  field: string;
  header: string;
}

interface IProps<T> {
  firstCol?: (string | number)[][];
  secondCol: ICol[];
  rows: T[];
  isCheck: boolean;
  isPointer: boolean;
  isCollapse?: boolean;
  isScroll?: boolean;
}

interface IRowsProps<T> {
  isCheck: boolean;
  row: T;
  secondCol: ICol[];
  isCollapse: boolean;
  isPointer: boolean;
  handlerSetCheckedArr: (key: string, isCheck: boolean, isAll?: "all") => void;
  isSelected: boolean;
}

interface ICollapseProps {
  isOnOff: boolean;
  selectTitle: string;
}

type Order = "asc" | "desc";

type IObject = { [k: string]: string | number | undefined };

const CustomTableCell = styled(TableCell)<TableCellProps & { cursor: string }>(
  ({ theme, cursor }) => {
    return {
      cursor: cursor,
    };
  }
);

const CustomCollapse = ({ isOnOff, selectTitle }: ICollapseProps) => {
  const [isBar, setIsBar] = React.useState(true);
  const handlerCheckIsBar = (type: boolean) => {
    setIsBar(type);
  };

  return (
    <TableRow>
      {/* <CustomCollapse in={isOnOff} timeout="auto" unmountOnExit /> */}
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={isOnOff} timeout="auto" unmountOnExit>
          <Box sx={{ display: "flex", padding: "20px" }}>
            {!isBar && (
              <Box sx={{ width: "30%" }}>
                <ChartController />
              </Box>
            )}

            <Box sx={{ width: isBar ? "100%" : "70%" }}>
              <Suspense>
                <Chart
                  selectTitle={selectTitle}
                  handlerCheckIsBar={handlerCheckIsBar}
                />
              </Suspense>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const Rows = <T extends { [index: string]: string | number | undefined }>({
  isCheck,
  row,
  secondCol,
  isCollapse,
  isPointer,
  handlerSetCheckedArr,
  isSelected,
}: IRowsProps<T>) => {
  const { isOnOff, handlerOnOff } = useOnOff();
  const [selectTitle, setSelectTitle] = React.useState("");
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const systemTypeState = useRecoilValue(systemType);
  return (
    <React.Fragment>
      <TableRow hover aria-checked={isSelected} selected={isSelected}>
        {isCheck && (
          <TableCell>
            <Checkbox
              checked={isSelected}
              onChange={(_e, c) => {
                handlerSetCheckedArr(String(row["runId"]), c);
              }}
            />
          </TableCell>
        )}
        {secondCol.map((col, idx) => {
          const value = row[col.field];
          if (
            (col.field === "startTime" || col.field === "duration") &&
            value !== "-"
          ) {
            return (
              value && (
                <CustomTableCell
                  cursor={isPointer ? "pointer" : ""}
                  onClick={(e) => {
                    if (isCheck) {
                      navigate(`/run/${row["runId"]}`, {
                        state: {
                          experimentId: row.experimentId,
                          rowId: row.runId,
                          type: systemTypeState,
                        },
                      });
                    }
                    if (isCollapse && row["key"] && row["key"]) {
                      setSelectTitle(row["key"] as string);
                      handlerOnOff(isOnOff);
                    }
                  }}
                  key={idx}
                >
                  {/* {value} */}
                  {col.field === "startTime" ? (
                    <Moment fromNow>{new Date(value)}</Moment>
                  ) : (
                    <Moment
                      duration={new Date(row.startTime!)}
                      filter={(e) => {
                        if (e.length > 10) {
                          if (i18n.language === "en") {
                            return `${String(
                              Number(e.split(" ")[0]) / 10
                            )} sec`;
                          } else {
                            return `${String(
                              Number(e.split(" ")[0]) / 100
                            )} 초`;
                          }
                        } else {
                          return `${e}`;
                        }
                      }}
                    >
                      {new Date(value)}
                    </Moment>
                  )}
                </CustomTableCell>
              )
            );
          }
          return value ? (
            <CustomTableCell
              cursor={isPointer ? "pointer" : ""}
              onClick={(e) => {
                if (isCheck) {
                  navigate(`/run/${row["runId"]}`, {
                    state: {
                      experimentId: row.experimentId,
                      rowId: row.runId,
                      type: systemTypeState,
                    },
                  });
                }
                if (isCollapse && row["key"]) {
                  setSelectTitle(row["key"] as string);

                  handlerOnOff(isOnOff);
                }
              }}
              key={idx}
            >
              {/* {value} */}
              {typeof value === "number" ? (
                value.toFixed(4)
              ) : value.toLowerCase() === "running" ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <Box
                  sx={
                    value === "FAILED" || value === "KILLED"
                      ? { color: "red" }
                      : value === "PAUSE"
                      ? { color: "blue" }
                      : {}
                  }
                >
                  {value}
                </Box>
              )}
            </CustomTableCell>
          ) : (
            <CustomTableCell
              cursor={isPointer ? "pointer" : ""}
              onClick={() => {
                if (isCheck) {
                  navigate(`/run/${row["runId"]}`, {
                    state: {
                      experimentId: row.experimentId,
                      rowId: row.runId,
                      type: systemTypeState,
                    },
                  });
                }
                if (isCollapse && row["key"]) {
                  setSelectTitle(row["key"] as string);

                  handlerOnOff(isOnOff);
                }
              }}
              key={idx}
            >
              {"-"}
            </CustomTableCell>
          );
        })}
      </TableRow>
      {isCollapse && (
        <Suspense>
          <CustomCollapse isOnOff={isOnOff} selectTitle={selectTitle} />
        </Suspense>
      )}
    </React.Fragment>
  );
};

function sortedRows<T extends IObject>(
  rows: T[],
  orderBy: string,
  order: string
) {
  if (orderBy === "") return rows;
  return rows.sort((a, b) => {
    const key = orderBy;
    const firstNum = a[key]! || Number.MIN_SAFE_INTEGER;
    const lastNum = b[key]! || Number.MIN_SAFE_INTEGER;

    if (key === "duration") {
      if (
        moment(b[key]).diff(b["startTime"]) <
        moment(a[key]).diff(a["startTime"])
      ) {
        return order === "asc" ? -1 : 1;
      }
      if (
        moment(b[key]).diff(b["startTime"]) >
        moment(a[key]).diff(a["startTime"])
      ) {
        return order === "asc" ? 1 : -1;
      }
    }
    if (lastNum < firstNum) {
      return order === "asc" ? -1 : 1;
    }
    if (lastNum > firstNum) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
}

const CustomTable = <
  T extends { [index: string]: string | number | undefined }
>({
  firstCol,
  secondCol,
  rows,
  isCheck,
  isPointer,
  isCollapse = false,
  isScroll = true,
}: IProps<T>) => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [checkedArr, setCheckedArr] = React.useState<Map<string, boolean>>(
    new Map()
  );

  const handlerSetCheckedArr = (
    key: string,
    isCheck: boolean,
    isAll?: "all"
  ): void => {
    if (isAll === "all" && isCheck) {
      const tempMap = new Map();
      rows.forEach((row) => {
        tempMap.set(String(row.runId), true);
      });
      setCheckedArr(tempMap);
      return;
    } else if (isAll === "all" && !isCheck) {
      setCheckedArr(new Map());
      return;
    }

    if (isCheck) {
      setCheckedArr((prevMap) => {
        const tempMap = new Map(prevMap);
        tempMap.set(key, true);
        return tempMap;
      });
    } else {
      setCheckedArr((prevMap) => {
        const tempMap = new Map(prevMap);
        tempMap.delete(key);
        return tempMap;
      });
    }
  };

  const handlerRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <ThemeProvider theme={mainTheme}>
      <Container>
        <TableContainer sx={isScroll ? { maxHeight: 440 } : {}}>
          <Table stickyHeader size={firstCol ? "small" : "medium"}>
            <TableHead>
              {firstCol && (
                <TableRow>
                  {firstCol.map((el, idx) => {
                    return (
                      <TableCell colSpan={el[1] as number} key={idx}>
                        {el[0]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
              <TableRow>
                {isCheck && (
                  <TableCell>
                    <Checkbox
                      onChange={(_e, isChecked) => {
                        handlerSetCheckedArr("", isChecked, "all");
                      }}
                    />
                  </TableCell>
                )}
                {secondCol.map((el, idx) => {
                  return (
                    <TableCell
                      onClick={(e) => {
                        if (firstCol) handlerRequestSort(e, el.field);
                      }}
                      key={idx}
                      sortDirection={orderBy === el.field ? order : false}
                      sx={{ width: "auto" }}
                    >
                      <TableSortLabel
                        active={orderBy === el.field}
                        direction={orderBy === el.field ? order : "asc"}
                        hideSortIcon={firstCol ? false : true}
                        sx={{ width: "80px" }}
                      >
                        {t(el.header)}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows(rows, orderBy, order).map((row, i) => {
                const isSelected = checkedArr.has(String(row.runId));
                return (
                  <Rows
                    isCheck={isCheck}
                    row={row}
                    secondCol={secondCol}
                    isCollapse={isCollapse}
                    isPointer={isPointer}
                    key={i}
                    handlerSetCheckedArr={handlerSetCheckedArr}
                    isSelected={isSelected}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};

const Container = styled(Paper)(({ theme }) => {
  return {
    boxShadow:
      theme.palette.mode === "dark"
        ? "0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%)"
        : "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  };
});

export default CustomTable;
