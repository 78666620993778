import { Button, styled, ThemeProvider, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { mainTheme } from "src/config/theme";

interface IProps {
  text: string;
  handleFunction: () => void;
  className?: string;
  disabled?: boolean;
}

const CustomButton = ({
  text,
  handleFunction,
  className,
  disabled = false,
}: IProps) => {
  const { t, i18n } = useTranslation();

  return (
    <ThemeProvider theme={mainTheme}>
      <Tooltip title={disabled ? "Running now" : ""} followCursor>
        <Root>
          <Button
            className={className}
            onClick={handleFunction}
            disabled={disabled}
          >
            {t(text)}
          </Button>
        </Root>
      </Tooltip>
    </ThemeProvider>
  );
};

const Root = styled("span")(({ theme }) => {
  return {
    "& .create": {},
    "& .delete": {
      color: theme.palette.error.main,
    },
    "& .interact": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    "& .interact:hover": {
      backgroundColor: theme.palette.mode === "dark" ? "black" : "#fff",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  };
});

export default CustomButton;
