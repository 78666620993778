import styled from "@emotion/styled";
import { Box, Tab, Tabs as MuiTabs, Tooltip } from "@mui/material";
import React, { Suspense } from "react";
import Artifacts from "./Artifacts";
import Metrics from "./Metrics";
import Parameters from "./Parameters";
import Result from "./Result";
import { tabsColumn } from "../../config/tabs";
import { useLocation } from "react-router";
import { useArtifacts } from "src/service/run/runService";
import { useTranslation } from "react-i18next";

interface IProps {
  runId: string;
  status: string | undefined;
  downloadUrl: string | undefined;
}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ padding: "30px 60px 0px 60px" }}>{children}</Box>
      )}
    </div>
  );
}

function Tabs({ runId, status, downloadUrl }: IProps) {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Root>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          sx={{ paddingLeft: "60px" }}
        >
          {tabsColumn.map((el, idx) => {
            if (el === "Result") {
              return (
                <Tab
                  label={t(el)}
                  key={idx}
                  disabled={
                    status && status === "FINISHED" && downloadUrl !== null
                      ? false
                      : true
                  }
                />
              );
            }
            return <Tab label={t(el)} key={idx} />;
          })}
        </MuiTabs>
      </Box>
      {/* TODO 각 패넬에 맞는 컴포넌트를 넣어줘야함 */}
      <TabPanel value={value} index={0}>
        <Suspense fallback={<div>Loading...</div>}>
          <Parameters />
        </Suspense>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Metrics />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Suspense>
          <Artifacts runId={runId} />
        </Suspense>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Result />
      </TabPanel>
    </Root>
  );
}

const Root = styled("div")(({ theme }) => ({
  "& .Mui-selected": {
    color: "#89B640",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#89B640",
    height: 3,
    color: "#89B640",
  },
}));

export default Tabs;
