import React from "react";
import { useLocation } from "react-router";
import { IState } from "src/pages/Run";
import { useMetrics } from "src/service/run/runService";
import CustomTable from "../common/CustomTable";

interface IProps {}

interface IRows {
  [index: string]: string | number;
  Name: string;
  Value: number;
}

function Metrics({}: IProps) {
  const { state } = useLocation() as unknown as {
    state: IState;
  };
  const { data } = useMetrics({ runId: state.rowId });

  const secondCol = [
    { field: "key", header: "Name", sticky: false },
    { field: "value", header: "Value", sticky: false },
    { field: "timestamp", header: "Date", sticky: false },
    { field: "step", header: "Step", sticky: false },
  ];

  return (
    <div>
      {data && (
        <CustomTable
          secondCol={secondCol}
          rows={data}
          isCheck={false}
          isPointer={true}
          isCollapse={true}
          isScroll={false}
        />
      )}
    </div>
  );
}

export default Metrics;
