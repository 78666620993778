import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { IState } from "src/pages/Run";
import { datasetsState, lineOptions } from "src/recoil/metrics";
import { useMetricsHistory } from "src/service/run/runService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  BarElement
);

interface IProps {
  selectTitle: string;
  handlerCheckIsBar: (type: boolean) => void;
}

function Chart({ selectTitle, handlerCheckIsBar }: IProps) {
  const [labels, setLabels] = React.useState<any>([selectTitle]);
  const [options, setOptions] = useRecoilState(lineOptions);
  const [datasets, setDatasets] = useRecoilState(datasetsState);
  const { state } = useLocation() as unknown as {
    state: IState;
  };
  const { data } = useMetricsHistory({
    runId: state.rowId,
    metricKey: selectTitle,
  });

  React.useEffect(() => {
    if (data) {
      if (data.length > 1) {
        handlerCheckIsBar(false);
        setDatasets((prev: any) => {
          return {
            ...prev,
            labels: data.map(
              (el: { [key: string]: string | number }) => el[options.xAxis]
            ),
            datasets: [
              {
                label: data[0].key,
                data: data.map((el) => el.value),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                pointRadius: options.isPoints ? 5 : 0,
              },
            ],
          };
        });
      } else {
        handlerCheckIsBar(true);
        setDatasets((prev: any) => {
          return {
            ...prev,
            labels: labels,
            datasets: [
              {
                label: data[0].key,
                data: data.map((el) => el.value),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
            ],
          };
        });
      }
    }
  }, [data, options]);

  return (
    <div>
      {data && data?.length > 1 ? (
        <Line data={datasets} />
      ) : (
        <Bar data={datasets} />
      )}
    </div>
  );
}

export default Chart;
