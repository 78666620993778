import React from "react";
import { DirectoryIcon, FileIcon } from "../icons";
import IFileInfo from "../IFileInfo";

interface IconByRowProp {
  fileInfo: IFileInfo;
}
export default function IconByRow(props: IconByRowProp) {
  const { fileInfo } = props;

  return <>{fileInfo.ext !== "" ? <FileIcon filename={fileInfo.name} style={{ height: 32, width: 32 }} /> : <DirectoryIcon name={fileInfo.name} style={{ height: 32, width: 32 }} />}</>;
}
