import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import { Box, Paper, styled, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { mainTheme } from "src/config/theme";
import { IData, IPostExperiment } from "src/dto";
import { alertOpenState } from "src/recoil/alert";
import { refreshState, systemType } from "src/recoil/common";
import { dialogOpenState } from "src/recoil/dialog";
import {
  postExperiment,
  useExperimentList,
} from "src/service/experiment/experimentService";
import { useSWRConfig } from "swr";
import { menu } from "../config/sidebarMenu";
import CustomCategory from "./common/CustomCategory";

export default function Sidebar() {
  const [refresh, setRefresh] = useRecoilState(refreshState);
  const { data } = useExperimentList<IData>({ refresh });
  const [dialogState, setDialogState] = useRecoilState(dialogOpenState);
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const setAlertState = useSetRecoilState(alertOpenState);

  const handlerCreateExperiment = () => {
    setDialogState({
      ...dialogState,
      title: "Create Experiment",
      type: "experiment",
      isOpen: true,
      handlerFunction: async <T,>(dataObj: T) => {
        const res = await postExperiment(
          dataObj as unknown as IPostExperiment
        ).catch((err) => {
          setAlertState({
            type: "Error",
            title: `Exist duplicated experiment named "${
              (dataObj as unknown as IPostExperiment).name
            }"!`,
            isOpen: true,
            handlerFunction: () => {},
            value: `you don't have ${
              (dataObj as unknown as IPostExperiment).name
            } experiment`,
          });
        });
        if (res?.status === 201) {
          mutate("experiments");
          setRefresh(new Date().getTime());
          setDialogState({ ...dialogState, isOpen: false });
        } else {
          return;
        }
      },
    });
  };
  const handlerDisabledExperiment = () => {
    setDialogState({
      ...dialogState,
      type: "disabled",
      title: "Disabled Experiment List",
      isOpen: true,
      handlerFunction: () => {},
      callbackFunction: () => {},
    });
  };

  const handlerNavigate = (id: string, name: { name: string }) => {
    navigate(`/${id}`, {
      state: {
        experimentId: id,
        experimentName: name.name,
        rowId: "",
        type: "",
      },
    });
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Root>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Experiment</Typography>
          <Box className="ExperimentFunctionBox">
            <AddBoxOutlinedIcon onClick={handlerCreateExperiment} />
            <SettingsApplicationsOutlinedIcon
              onClick={handlerDisabledExperiment}
            />
          </Box>
        </Box>
        {data &&
          menu.map((el, idx) => {
            const key = el[1].toLowerCase() as systemType;

            return (
              <Box key={idx}>
                <CustomCategory
                  title={el[0]}
                  data={data[el[2]]}
                  keyValue={key}
                  handlerNavigate={handlerNavigate}
                />
              </Box>
            );
          })}
      </Root>
      <Container>
        <Outlet />
      </Container>
    </ThemeProvider>
  );
}
// ${theme.palette.background}`
const Root = styled(Paper)(({ theme }) => {
  return {
    fontSize: "20px",
    minWidth: "250px",
    width: "250px",
    maxWidth: "250px",
    borderRadius: "0px",
    "& li": {
      position: "relative",
      marginBottom: "6px",
    },
    "& ul": {
      listStyle: "none",
      paddingLeft: "20px",
      fontSize: "16px",
    },

    "& .link": {
      display: "block",
      width: "70%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textDecoration: "none",
      fontSize: "0.9rem",
      cursor: "pointer",
      color:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 1)"
          : "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiTypography-root": {
      margin: "12px 0px 0px 12px",
    },
    "& .MuiSvgIcon-root": {
      margin: "12px 12px 0px 0px",
      cursor: "pointer",
    },
    "& .MuiTypography-h6 ": {
      fontSize: "1.15rem",
      margin: "0px 0px 0px 12px",
      whiteSpace: "pre-line",
    },
    "& .MuiDivider-root": {
      borderColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 1)"
          : "rgba(0, 0, 0, 0.08)",
    },
    "& .boxContainer": {
      position: "absolute",
      left: "75%",
      top: "0%",
    },
    "& .svg": {
      color: theme.palette.primary.main,
      margin: "0px",
    },
    "& .MuiAccordionSummary-root": {
      flexDirection: "row-reverse",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiArrowCategory": {
      margin: "0px",
      fontSize: "0.8rem",
    },
    "& .ExperimentFunctionBox": {
      display: "flex",
      alignItems: "center",
    },
  };
});

const Container = styled(Paper)(({ theme }) => {
  return {
    width: "calc(100vw - 250px)",
    borderRadius: "0px",
    boxShadow: "0px",
    borderLeft: "1px solid",
    borderColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.8)"
        : "rgba(0, 0, 0, 0.08)",
  };
});
