import React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { IUserDirs } from "../";
import {
  FolderDocumentsYellow,
  FolderHomeRed,
  FolderMusicGreen,
  FolderPicturesBlue,
  FolderVideoPurple,
} from "../icons";
import { TreeItem, treeItemClasses, TreeItemProps, TreeView } from "@mui/lab";
import { Box, styled, SvgIconProps, Typography } from "@mui/material";

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

interface LeftTreeViewProps {
  homePath: string;
  currentPath?: string;
  userDirs: IUserDirs;
  setTrySearchBasePath?: (newTrySearchBasePath: string) => void;
}

export default function LeftTreeView(
  props: LeftTreeViewProps
): React.ReactElement {
  const onClickHandler = (event: React.MouseEvent, path: string): void => {
    if (path !== "") {
      props.setTrySearchBasePath && props.setTrySearchBasePath(path);
    }
  };
  return (
    <TreeViewRoot>
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {/* <StyledTreeItem nodeId="1" labelText="Recent" onClick={(e) => onClickHandler(e, "Recent")} labelIcon={FolderRecentYellow} /> */}
        {/* <StyledTreeItem nodeId="2" labelText="Home" onClick={(e) => onClickHandler(e, props.homePath)} labelIcon={FolderHomeRed} />
        <StyledTreeItem nodeId="3" labelText="Documents" onClick={(e) => onClickHandler(e, props.userDirs.documents)} labelIcon={FolderDocumentsYellow} />
        <StyledTreeItem nodeId="4" labelText="Music" onClick={(e) => onClickHandler(e, props.userDirs.music)} labelIcon={FolderMusicGreen} />
        <StyledTreeItem nodeId="5" labelText="Pictures" onClick={(e) => onClickHandler(e, props.userDirs.pictures)} labelIcon={FolderPicturesBlue} />
        <StyledTreeItem nodeId="6" labelText="Videos" onClick={(e) => onClickHandler(e, props.userDirs.videos)} labelIcon={FolderVideoPurple} /> */}
      </TreeView>
    </TreeViewRoot>
  );
}

const TreeViewRoot = styled("div")(({ theme }) => ({
  "& .MuiTreeView-root": {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
    padding: theme.spacing(5, 0),
  },
}));
