import { Box, Paper, styled } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { IState } from "src/pages/Run";
import { alertOpenState } from "src/recoil/alert";
import { handlerGetHtmlFile } from "src/service/run/predictService";
import CustomButton from "../common/CustomButton";
import CustomTable from "../common/CustomTable";
import CustomAlert from "../common/CustomAlert";

interface IProps {}

interface IRows {
  [index: string]: string | number;
  FileName: string;
  CropName: string;
  Code: string;
  Reliability: 12;
  url: string;
}

function Result({}: IProps) {
  const [alertState, setAlertState] = useRecoilState(alertOpenState);
  const { state } = useLocation() as unknown as {
    state: IState;
  };

  const handlerDownload = async () => {
    const res = await handlerGetHtmlFile({
      experimentId: state.experimentId,
      runId: state.rowId,
    });
    if (res.status >= 400) {
      setAlertState((prev) => ({
        type: "error",
        title: "Failure",
        isOpen: true,
        handlerFunction: () => {},
        value: "Fail download result file",
      }));
    } else if (res.status < 300) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "result.html");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const secondCol = [
    {
      field: "FileName",
      header: "FileName",
      sticky: false,
    },
    {
      field: "CropName",
      header: "CropName",
      sticky: false,
    },
    {
      field: "Code",
      header: "Code",
      sticky: false,
    },
    {
      field: "Reliability",
      header: "Reliability",
      sticky: false,
    },
  ];

  const rows: IRows[] = [
    {
      FileName: "Mock",
      CropName: "mock data",
      Code: "ㄷㄱㅎㄷㄱㅁㅎ",
      Reliability: 12,
      url: "../../download.jpeg",
    },
    {
      FileName: "Mock",
      CropName: "mock data",
      Code: "ㄷㄱㅎ",
      Reliability: 12,
      url: "../../download.jpeg",
    },
    {
      FileName: "Mock",
      CropName: "mock data",
      Code: "ㄷㄱㅎㄷㅎ",
      Reliability: 12,
      url: "../../download.jpeg",
    },
    {
      FileName: "Mock",
      CropName: "mock data",
      Code: "ㅎㄷㄱㅎㄱㄷㅎ",
      Reliability: 12,
      url: "../../download.jpeg",
    },
  ];

  return (
    <Root>
      <Box className={"HtmlDownloadButtonContainer"}>
        <CustomButton text={"DOWNLOAD"} handleFunction={handlerDownload} />
      </Box>
      {/* <CustomTable<IRows>
        secondCol={secondCol}
        rows={rows}
        isCheck={false}
        isPointer={false}
      /> */}
      <CustomAlert />
    </Root>
  );
}

const Root = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  "& .HtmlDownloadButtonContainer": {
    display: "flex",
    // flexDirection: "row-reverse",
    justifyContent: "center",
  },
}));

export default Result;
