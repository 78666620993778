import styled from "@emotion/styled";
import React, { Suspense } from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { useRecoilState, useSetRecoilState } from "recoil";
import { contentOpenState } from "src/recoil/artifacts";
import { useArtifacts } from "src/service/run/runService";
import { useLocation } from "react-router";
import { IState } from "src/pages/Run";
type Data = {
  path: string;
  isDir: boolean;
  fileSize?: number;
};

interface ITreeProps {
  data: Data[];
}

function sortIsDir(data: Data[]): Data[] | [] {
  let result = [
    ...data.filter((el) => el.isDir),
    ...data.filter((el) => !el.isDir),
  ];
  return result as Data[] | [];
}

function ParentNode({ title, runId }: { title: string; runId: string }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { data } = useArtifacts({ runId: runId, path: title });

  const recursiveNode = React.useCallback(() => {
    if (data) {
      return data.fileInfo.length > 0 ? (
        <Suspense>
          <Tree data={data.fileInfo} />
        </Suspense>
      ) : (
        <div></div>
      );
    }
  }, [data]);
  return (
    <>
      <Parent
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? <ArrowRightOutlinedIcon /> : <FolderOpenOutlinedIcon />}
        {title}
      </Parent>
      {isOpen && <div>{recursiveNode()}</div>}
    </>
  );
}

function Tree({ data }: ITreeProps) {
  const setIsContentOpen = useSetRecoilState(contentOpenState);
  const { state } = useLocation() as unknown as {
    state: IState;
  };
  return (
    <Root>
      {sortIsDir(data).map((el: Data, idx: number) => {
        const extension = el.path.split(".");
        if (el.isDir) {
          return <ParentNode title={el.path} key={idx} runId={state.rowId} />;
        } else {
          return (
            <Child
              key={idx}
              onClick={() => {
                if (extension[extension.length - 1] !== "pth")
                  setIsContentOpen({
                    isOpen: true,
                    title: el.path,
                    runId: state.rowId,
                    extension: extension[extension.length - 1],
                  });
              }}
            >
              <InsertDriveFileOutlinedIcon />
              {el.path}
            </Child>
          );
        }
      })}
    </Root>
  );
}
const Root = styled("div")(({ theme }) => ({
  marginLeft: "10px",
  display: "flex",
  flexDirection: "column",
  wordWrap: "break-word",
}));

const Child = styled("span")(({ theme }) => ({
  // marginLeft: "20px",
  cursor: "pointer",
}));

const Parent = styled("span")(({ theme }) => ({
  // marginLeft: "20px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
}));

export default Tree;
