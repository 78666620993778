import { Box, Input, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TrainObjectDetectionReqDto } from "src/dto";
import { systemType } from "src/recoil/common";
import useModel from "src/service/common/useModel";
import useInputs from "src/service/hook/useInputs";
import CustomComboBox from "../common/CustomComboBox";
import { ContentBox } from "../common/CustomDialog";
import CustomInput from "../common/CustomInput";
import { FileExplorerDialog } from "../fileExplorer";
import CloseIcon from "@mui/icons-material/Close";
import { InputWrapper } from "../common/CustomAutoComplete";
import {
  IPredictReqDto,
  IPredictReqDtoTemp,
} from "src/service/run/predictService";
import { dialogOpenState } from "src/recoil/dialog";

function PredictLearning({
  handlerSetData,
  predictType,
}: {
  handlerSetData: <T>(data: T) => void;
  predictType: string;
}) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [urlKey, setUrl] = React.useState("");
  const systemTypeState = useRecoilValue(systemType);
  const [classesTags, setClassesTags] = React.useState<string[]>([]);
  const dialogState = useRecoilValue(dialogOpenState);

  const fileDialogOpenHandler = (title: string) => {
    setUrl((prev) => title);
    setIsOpen(true);
  };
  const [
    { inputData, outputData, fileName, artifactUrl, multiSpectral },
    handlerInput,
    reset,
  ] = useInputs<IPredictReqDtoTemp>({
    inputData: "",
    outputData: "",
    fileName: "",
    multiSpectral: "RGB",
    artifactUrl: dialogState.value,
  });

  React.useEffect(() => {
    handlerSetData<IPredictReqDto>({
      inputData,
      outputData,
      outputFileName: fileName!,
      artifactUrl,
      multiSpectral,
    });
  }, [inputData, outputData, fileName, artifactUrl, multiSpectral]);

  return (
    <Box>
      {predictType === "predictClassification" && (
        <ContentBox sx={{ display: "flex" }}>
          <Typography sx={{ flex: 0.3 }} align={"right"}>
            {t("Data type")}
          </Typography>
          <CustomComboBox
            combos={["RGB", "multispectral"]}
            name="multiSpectral"
            handlerFunction={handlerInput}
          />
        </ContentBox>
      )}

      {multiSpectral === "RGB" && (
        <ContentBox>
          <CustomInput title={"fileName"} handlerFunction={handlerInput} />
        </ContentBox>
      )}

      <ContentBox>
        <CustomInput
          title={"inputData"}
          handlerFunction={handlerInput}
          type={"box"}
          inputClickFunction={fileDialogOpenHandler}
          path={inputData}
        />
      </ContentBox>

      <ContentBox>
        <CustomInput
          title={"outputData"}
          handlerFunction={handlerInput}
          type={"box"}
          inputClickFunction={fileDialogOpenHandler}
          path={outputData}
        />
      </ContentBox>

      <ContentBox sx={{ display: "flex" }}>
        <Typography sx={{ flex: 0.3 }} align={"right"}>
          {t("artifactUrl")}
        </Typography>
        <input disabled={true} defaultValue={artifactUrl} />
      </ContentBox>

      {isOpen && (
        <Suspense>
          <FileExplorerDialog
            title="서버 스토리지 내부 경로 선택"
            description="FTP를 통해 미리 업로드된 파일이 있는 경로(폴더)를 선택합니다."
            open={isOpen}
            onClose={(selectedPath: string | undefined, e) => {
              if (selectedPath) {
                handlerInput(e, urlKey, undefined, selectedPath);
              }
              setIsOpen(false);
            }}
          />
        </Suspense>
      )}
    </Box>
  );
}

export default PredictLearning;
