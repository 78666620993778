import { Breadcrumbs, Link, styled, Typography } from "@mui/material";
import React from "react";
import { FolderHomeRed } from "../icons";

interface FilePathBreadcCrumbsProps {
  homePath: string;
  currentPath: string;
  setTrySearchBasePath?: (newTrySearchBasePath: string) => void;
}

export const FilePathBreadcCrumbs = (
  props: FilePathBreadcCrumbsProps
): React.ReactElement => {
  const [pathes, setPathes] = React.useState<string[]>([]);
  const [lastDir, setLastDir] = React.useState<string>("");

  React.useEffect(() => {
    if (props.currentPath !== "/") {
      const pathes: string[] = props.currentPath
        .replace(props.homePath, "{Home}")
        .split(/\\|\//);
      setLastDir(pathes.pop() || "");
      setPathes(pathes);
    }
  }, [props]);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    idx: number
  ): void => {
    const selectedPathes = pathes.slice(0, idx + 1).join("/");
    const newPath = selectedPathes.replace("{Home}", props.homePath);
    props?.setTrySearchBasePath && props.setTrySearchBasePath(newPath);
  };
  return (
    <Root>
      <Breadcrumbs maxItems={2} aria-label="breadcrumb">
        <Link
          color="inherit"
          onClick={() =>
            props?.setTrySearchBasePath &&
            props.setTrySearchBasePath(props.homePath)
          }
        >
          <span style={{ display: "flex" }}>
            <FolderHomeRed style={{ flexGrow: 1 }} />
            <Typography
              variant="body1"
              style={{ flexGrow: 1, cursor: "pointer" }}
            >
              Home
            </Typography>
          </span>
        </Link>
        {pathes.map(
          (dir, idx) =>
            idx !== 0 && (
              <Link
                sx={{ cursor: "pointer" }}
                color="inherit"
                onClick={(e) => handleClick(e, idx)}
                key={idx}
              >
                {dir}
              </Link>
            )
        )}
        {props.homePath !== props.currentPath && (
          <Typography color="textPrimary">{lastDir}</Typography>
        )}
      </Breadcrumbs>
    </Root>
  );
};

const Root = styled("div")(({ theme }) => ({
  "& .MuiBreadcrumbs-root": {
    margin: theme.spacing(1),
    userSelect: "none",
    maxWidth: 650,
  },
  "& .MuiLink-root": {
    display: "flex",
  },
  "& .MuiIcon-root": {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));
