import styled from "@emotion/styled";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { lineOptions } from "src/recoil/metrics";

type Props = {};

const RadioButtons = () => {
  const [options, setOptions] = useRecoilState(lineOptions);

  return (
    <FormControl>
      <Typography>X-axis</Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="step"
        name="radio-buttons-group"
        onChange={(e) => {
          setOptions({ ...options, xAxis: e.target.value });
        }}
      >
        <FormControlLabel value="step" control={<Radio />} label="Step" />
        {/* <FormControlLabel
          value="Time (Wall)"
          control={<Radio />}
          label="Time (Wall)"
        /> */}
        <FormControlLabel
          value="timestamp"
          control={<Radio />}
          label="Time (Relative)"
        />
      </RadioGroup>
    </FormControl>
  );
};

const ChartController = (props: Props) => {
  const [options, setOptions] = useRecoilState(lineOptions);

  return (
    <Root>
      <Box>
        <Box>
          <Typography>Points</Typography>{" "}
          <Switch
            defaultChecked
            onChange={(_, checked) => {
              setOptions({
                ...options,
                isPoints: checked,
              });
            }}
          />
        </Box>
        {/* <Box>
          <Typography>Line Smoothness</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "60%", marginRight: "7%" }}>
              <CustomSlide
                defaultValue={1}
                handlerInput={(e, name, value) => {
                  console.log(name, value);
                }}
                name={"lineSmoothness"}
              />
            </Box>
          </Box>
        </Box> */}
        <Box>
          <RadioButtons />
        </Box>
        {/* <Box>
          <CustomAutoComplete />
        </Box> */}
      </Box>
    </Root>
  );
};

const Root = styled("div")(({ theme }) => ({
  "& .customThumbtext": {
    color: "#fff",
  },
}));

export default ChartController;
